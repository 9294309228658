//PACKS
import React, { useState, useEffect } from 'react'

//COMPONENTS
import AccountHero from '../components/AccountHero'
import FavoriteContentSection from '../components/FavoriteContentSection'
import StatisticsGrid from '../components/StatisticsGrid'
import Footer from '../components/Footer'
import BlurredBackground from '../components/BlurredBackground'

//UTILS
import useFetch from "../utils/useFetch"

//ASSETS
import login from "../assets/images/login-bg.jpg"

export default function Account() {


  const userDataResponse = useFetch('/french/users/me')
  const userData = userDataResponse?.data?.data?.attributes

  const likedActivitiesResponse = useFetch('/french/activities/liked')
  const likedActivities = likedActivitiesResponse?.data?.data

  const likedVideosResponse = useFetch('/french/videos/liked')
  const likedVideos = likedVideosResponse?.data?.data

  let likedContent = null

  if (likedVideos && likedActivities) {
    likedContent = [...likedVideos, ...likedActivities]
  }


  return (
    <div>
      <BlurredBackground image={login} />
      <div style={{ height: '88px' }} />
      {
        userData &&
        <div className="fit">
          <AccountHero userData={userData} />
          <div className="sectionSpacer" />
        </div>
      }
      {
        likedContent &&
        <>
          <FavoriteContentSection content={{ title: "Contenuti favoriti", cards: likedContent }} color={"light"} />
          <div className="sectionSpacer sectionSpacer-white" />
        </>
      }
      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  )
}