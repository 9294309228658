//PACKS
import React, { useState, useEffect } from 'react'

//COMPONENTS
import Responsive from '../utils/Responsive'
import ContentCard from './ContentCard'
import MobileCarousel from './MobileCarousel'

export default function DefaultContentSection({ content, color, viewportWidth, size }) {

  const title = content.title
  const cards = content.cards?.data?.data

  return title && cards && color &&
    <div>
      <Responsive>
        <Mobile color={color} cards={cards} title={title} />
        <Desktop color={color} cards={cards} title={title} size={size} />
      </Responsive>
    </div>
}

function Mobile({ color, cards, title }) {
  return (
    <div className="container-w-titlepad" style={{ backgroundColor: color === "light" ? "white" : "black" }}>
      <p className={`titlepad titlepad-${color}`}>{title}</p>
      <div className="fit ">
        <MobileCarousel cards={cards} color={color} />
      </div>
    </div>
  )
}

function Desktop({ color, cards, title, size }) {

  const length = size === 'all' ? cards.length : size

  return (
    <div className="container-w-titlepad" style={{ backgroundColor: color === "light" ? "white" : "black" }}>
      <p className={`titlepad titlepad-${color}`}>{title}</p>
      <div className="fit grid">
        {cards.slice(0, length ? length : 3).map((card, id) => (
          <div key={id}>
            <ContentCard content={card} color={color} />
          </div>
        ))}
      </div>
    </div>
  )
}