//PACKS
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { setModal } from "../utils/setModal";

//STYLES
import "../styles/featuredCard.scss";

//PACKS
import HalfMoonImages from "./HalfMoonImages";
import CardActions from "./CardActions";

import { cleanURI } from "../utils/cleanURI.js";
import { redirectToCalendar } from "../utils/redirectToCalendar.js";

export default function FeaturedCard({ content, type, ...props }) {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const search = useLocation().search;

  return (
    // content && type &&
    <div
      className={`featured-card ${props.inModal && "featured-card-modal"}`}
      style={{
        backgroundImage: `url(${
          content?.attributes?.cover_pict || content?.attributes?.preview_pict
        })`,
      }}
      onClick={() => {
        type !== "closed" && setModal(content, navigate, location, search);
      }}
    >
      <div className="shadow" />
      <div className={`top-bar ${type !== "closed" ? "" : "top-bar-closed"}`}>
        <AnimatePresence>
          {type !== "closed" && (
            <HalfMoonImages
              images
              likes={
                content?.attributes?.photos_from_random_users_who_liked_this
              }
              number={content?.attributes?.like_count}
            />
          )}
        </AnimatePresence>
        <CardActions
          isLiked={content?.attributes?.is_liked}
          type={content?.type}
          id={content?.id}
          slug={content?.attributes?.slug}
        />
      </div>
      <AnimatePresence>
        {type !== "closed" && (
          <div className="bot-bar">
            <div className="text">
              <p className="title">{content?.attributes?.title}</p>
              <p className="location">
                {content?.attributes?.activity_organizer?.name}
              </p>
            </div>
            <button
              className="add-button"
              onClick={() => {
                setModal(content, navigate, location, search);
              }}
              // onClick={(event) => { event.stopPropagation(); redirectToCalendar(content) }}
            >
              {/* <div className='icon'>{addIcon}</div>
              <p>{content?.attributes?.formatted_date.slice(0, 6)}</p>
              <div className="separator" />
              <p>{content?.attributes?.formatted_date.slice(7, 20)}</p> */}
              <p>Scopri di più</p>
            </button>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}

const addIcon = (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.0625 2.25V3.9375M12.9375 2.25V3.9375M2.25 14.0625V5.625C2.25 5.17745 2.42779 4.74823 2.74426 4.43176C3.06072 4.11529 3.48995 3.9375 3.9375 3.9375H14.0625C14.5101 3.9375 14.9393 4.11529 15.2557 4.43176C15.5722 4.74823 15.75 5.17745 15.75 5.625V14.0625M2.25 14.0625C2.25 14.5101 2.42779 14.9393 2.74426 15.2557C3.06072 15.5722 3.48995 15.75 3.9375 15.75H14.0625C14.5101 15.75 14.9393 15.5722 15.2557 15.2557C15.5722 14.9393 15.75 14.5101 15.75 14.0625M2.25 14.0625V8.4375C2.25 7.98995 2.42779 7.56073 2.74426 7.24426C3.06072 6.92779 3.48995 6.75 3.9375 6.75H14.0625C14.5101 6.75 14.9393 6.92779 15.2557 7.24426C15.5722 7.56073 15.75 7.98995 15.75 8.4375V14.0625"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
    >
      <path
        d="M5.5 1V8.63636M9 4.81818H2"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
