//PACKS
import React, { useState, useEffect } from 'react'

//STYLES
import "../styles/blurredBackground.scss"

export default function BlurredBackground(props) {

  const [image, setImage] = useState(null)

  useEffect(() => {
    setImage(props.image)
  }, [props])

  return (
    <div className="blurred-background">
      <div className="shadow" />
      {image && <img src={image} />}
    </div>
  )
}
