import React, { useState, useEffect } from 'react'
import StatisticsGrid from '../components/StatisticsGrid'
import Footer from '../components/Footer'
import AutovalutazioneCard from '../components/AutovalutazioneCard'
import SeeMoreBanner from '../components/SeeMoreBanner'
import CityPageStackedCard from '../components/CityPageStackedCard'
import AutovalutazioneStackedCard from '../components/AutovalutazioneStackedCard'
import DefaultContentSection from '../components/DefaultContentSection'
import BlurredBackground from '../components/BlurredBackground'
import axiosInstance from "../utils/api";

import bannerImage from "../assets/images/banner-autovalutazione.jpg"

const Autovalutazione = () => {


  const [recommendedEvents, setRecommendedEvents] = useState(null)

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // const likedContentResponse = await axiosInstance.get('/like?page=1');
        // setLikedContent(likedContentResponse.data);

        const recommendedEventsResponse = await axiosInstance.get('/home/eventi_curati');
        setRecommendedEvents(recommendedEventsResponse.data.new_activity);

        setLoading(false);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data. Please try again.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      <div style={{ height: '88px' }} />
      <BlurredBackground image={bannerImage} />
      <div className="fit">
        <AutovalutazioneStackedCard />
      </div>
      {/* ------------------------------------------------------------------------------------------------ */}
      <div className="sectionSpacer" />
      <div className="fit grid-2">
        {autovalutazioneCards.map((card, index) => (
          <div key={index}>
            <AutovalutazioneCard content={card} />
          </div>
        ))}
      </div>
      <div style={{ height: '88px' }} />
      {/* ------------------------------------------------------------------------------------------------ */}

      {/* {
        recommendedEvents &&
        <>
          <DefaultContentSection viewportWidth={props.viewportWidth} content={{ title: "Suggeriti per te", cards: recommendedEvents.slice(0, 3) }} color={"dark"} />
          <div className="sectionSpacer" />
        </>
      } */}

      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  )
}

export default Autovalutazione



const autovalutazioneCards = [
  {
    "title": "Scopri i tuoi talenti",
    "description": "“Scopri i tuoi talenti” è uno strumento di self-assessment e orientamento progettato da MakerDojo che permette a studenti e studentesse di identificare le aree in cui in cui esiste, a livello individuale, il maggior potenziale di sviluppo dei punti di forza.",
    "photo": "forms_1",
    "link": "https://www.readytodigital.it/autovalutazione",
    "stats": [
      {
        "key": "categorie",
        "value": 6
      },
      {
        "key": "domande",
        "value": 88
      },
      {
        "key": "minuti",
        "value": 15
      },
    ]
  },
  {
    "title": "Digital Readiness",
    "description": "Un self assessment che permette di avere indicazioni in merito al grado di maturità digitale della tua azienda. Restituisce automaticamente al termine del questionario un report sintetico con il posizionamento dell’impresa rispetto ai processi produttivi",
    "photo": "forms_2",
    "link": "https://www.readytodigital.it/surveys/1",
    "stats": [
      {
        "key": "categorie",
        "value": 3
      },
      {
        "key": "domande",
        "value": 11
      },
      {
        "key": "minuti",
        "value": 5
      },
    ]
  },
  {
    "title": "Self Cultura X.0",
    "description": "Mappa la maturità digitale della tua istituzione culturale; posizonamento, strategia, produzione. Restituisce automaticamente al termine del questionario un report sintetico ed indicazione sul grado di trasformazione in attore di progettualità ed innovazione",
    "photo": "forms_3",
    "link": "https://www.readytodigital.it/surveys/3",
    "stats": [
      {
        "key": "categorie",
        "value": 4
      },
      {
        "key": "domande",
        "value": 11
      },
      {
        "key": "minuti",
        "value": 5
      },
    ]
  },
  {
    "title": "First Match Diritto Sul Informatico",
    "description": "Prenota un'ora di incontro o call gratuiti con un Consulente di Colin & Partners. Saremo felici di supportarti, rispetto alle tue esigenze sui temi del diritto delle nuove tecnologie, nella ricerca dell’approccio più efficace per il tuo business.",
    "photo": "forms_4",
    "link": "https://firstmatchdirittoinformatico.it/",
    "stats": [
      {
        "key": "categorie",
        "value": 1
      },
      {
        "key": "domande",
        "value": 2
      },
      {
        "key": "minuti",
        "value": 60
      },
    ]
  },
]
