import React, { useCallback, useEffect, useState, memo } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { flushSync } from "react-dom";
import StackedCard from "./StackedCard";
import "../styles/verticalCarousel.css";

const TWEEN_FACTOR = 0.4;

const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max);

const options = { axis: "y" };
const SLIDE_COUNT = 5;

const HomepageVerticalSlider = ({ videos }) => {

  const data = videos?.data?.data
  const loading = videos?.loading

  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [tweenValues, setTweenValues] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    if (!data) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
      return numberWithinRange(tweenValue, 0, 1);
    });

    setTweenValues(styles);

    const current = Math.round(scrollProgress * (data.length - 1));
    setCurrentSlide(current);
  }, [emblaApi, setTweenValues, setCurrentSlide, data]);

  useEffect(() => {
    if (!emblaApi) return;

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);

  }, [emblaApi, onScroll, currentSlide]);

  return (
    <>
      {data &&
        <div className="vertical-carousel">
          <div className="embla__bullets">
            {data.map((_, index) => (
              <div
                key={index}
                className={`embla__bullet ${index !== currentSlide ? "active" : ""
                  }`}
                onClick={() => emblaApi.scrollTo(index)}
              />
            ))}
          </div>
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {data.map((card, index) => (
                <div
                  className="embla__slide"
                  key={index}
                // style={{ marginTop: index !== 0 ? '10px' : '0' }}
                >
                  <div
                    className="embla__scale"
                    style={{
                      ...(tweenValues.length && {
                        transform: `scale(${tweenValues[index]})`,
                      }),
                    }}
                  >
                    <StackedCard data={card} />

                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>}
    </>
  );
};

export default memo(HomepageVerticalSlider);
