//UTILS
import axiosInstance from "./api"
import validateFields from "./validateFields"

export default async function register(e, setError) {

  e.preventDefault();

  const email = e.target.email.value
  const password = e.target.password.value
  const password_confirmation = e.target.password_confirmation.value
  const first_name = e.target.first_name.value
  const last_name = e.target.last_name.value

  if (password !== password_confirmation) {
    setError({ status: true, fields: ["La password e la conferma della password devono corrispondere."] })
    return
  }

  const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+-=|\\]).{8,32}$/;

  if (!pattern.test(password)) {
    setError({ status: true, fields: ["Questo modello di espressione regolare valida le password che contengono almeno una cifra, una lettera minuscola, una lettera maiuscola, un carattere speciale e hanno una lunghezza compresa tra 8 e 32 caratteri."] })
    return;
  }

  const fields = validateFields(e)

  if (fields.valid) {
    try {
      const response = await axiosInstance.post('/auth/',
        {
          "email": email,
          "password": password,
          "password_confirmation": password_confirmation,
          "first_name": first_name,
          "last_name": last_name
        }
      );

      const { "access-token": accessToken } = response.headers
      const { "client": client } = response.headers
      const { "uid": uid } = response.headers

      const storeTokenPromise = new Promise((resolve, reject) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('client', client);
        localStorage.setItem('uid', uid);
        resolve();
      });

      storeTokenPromise
        .then((result) => {
          window.location.href = '/'
        })
        .catch((error) => {
          setError({ status: true, fields: [error] })
        });


    } catch (err) {
      const error = err?.response?.data?.errors?.full_messages
      if (error) {
        setError({ status: true, fields: error })
      } else {
        setError({ status: true, fields: ["Somethig went wrong"] })
      }
    }
  } else {
    setError({ status: true, fields: fields.errorFields })
  }

}
