//PACKS
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";
import { InstantSearch, Hits, useSearchBox, Index } from 'react-instantsearch';

//UTILS
import { setModal } from "../utils/setModal";
import { preventBodyScrolling } from "../utils/preventBodyScrolling";

//STYLES
import "../styles/search.scss"

export default function SearchBar({ handleSearchClick, clicked }) {

  const [hitClicked, setHitClicked] = useState(false)

  function handleHitClick(value) {
    // this helps clearing the input's value,
    // it doesn't have to do with the animation logics
    setHitClicked(value)
  }

  useEffect(() => {
    if (clicked) { setHitClicked(false) }
  },
    [clicked]
  )

  return (
    <div className="algolia-container">
      <InstantSearch searchClient={searchClient} >
        <CustomSearchBox handleSearchClick={handleSearchClick} clicked={clicked} hitClicked={hitClicked} />
        {
          clicked &&
          <div className="hits-scrollbox">
            <Index indexName="AlgoliaInfoCollector">
              <Hits
                hitComponent={({ hit }) =>
                  <Hit hit={hit} handleSearchClick={handleSearchClick} handleHitClick={handleHitClick} />
                }
              />
            </Index>
          </div>
        }
      </InstantSearch>
    </div>
  )
}

function CustomSearchBox(props) {

  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  function setQuery(newQuery) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  useEffect(() => {
    if (props.hitClicked) {
      setQuery('')
    }
  }, [props.hitClicked])

  return (
    <div className="ais-SearchBox">
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <input
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Ricerca"
          spellCheck={false}
          maxLength={50}
          type="search"
          value={inputValue}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
          onFocus={() => {
            props.handleSearchClick(true)
            preventBodyScrolling("hidden")
          }}
        />
        <div className="buttons">
          <button className="ais-SearchBox-clear"
            onClick={() => {
              props.handleSearchClick(false)
              preventBodyScrolling("none")
              setQuery('');
            }}
            style={{ opacity: props.clicked ? 1 : 0, pointerEvents: props.clicked ? 'all' : "none" }}
          >
            {icons.trash}
            <p>Annulla</p>
          </button>
        </div>
      </form>
    </div >
  );
}

function Hit({ hit, handleSearchClick, handleHitClick }) {

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const search = useLocation().search

  const content = {
    type: hit?.class_name?.toLowerCase(),
    id: hit?.objectID,
    attributes: { slug: hit.slug }
  }

  return (
    <div className="hit-container">
      <div className="hit-title">
        <div className="tags">
          <div className="type">
            {icons[hit?.class_name?.toLowerCase()]}
            <p>{hit.class_name}</p>
          </div>
          <button
            className="go"
            onClick={() => {
              setModal(content, navigate, location, search);
              handleSearchClick(false)
              handleHitClick(true)
              preventBodyScrolling("none")
            }}>
            <p>{hit.class_name === 'Activity' ? 'Scopri di più' : "Vedi ora"} </p>
          </button>
        </div>
        <h1>{hit.title}</h1>
        <p>{hit.subtitle || hit.description}</p>
      </div>
    </div>
  );
}

const originalSearchClient = algoliasearch(
  '9HPWXSSVL6',
  'cacc3e9c6700d15777d14b82db18b342'
);

originalSearchClient.multipleQueries([{ indexName: "Video" }, { indexName: "Activity" }])

const searchClient = {
  ...originalSearchClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0
        }))
      });
    }
    return originalSearchClient.search(requests);
  }
};

const icons = {
  video: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
    </svg>
  ),
  activity: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
    </svg>
  ),
  search: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
    </svg>
  ),
  backspace: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
    </svg>
  ),
  trash: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
    </svg>
  )
}