//PACKS
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//STYLES
import "../styles/navbar.scss";

//ASSETS
import { hamButtons, navbarButtons, cities } from "../assets/jsons/hamButtons";
import HamburgerModal from "./HamburgerModal";
import LinksAndSearchBar from "./LinksAndSearchBar";

const routesWithNavbar = [
  "/",
  "/calendario",
  "/video",
  "/education",
  "/chiedi-al-lab",
  "/maturita-digitale",
  "/citta/forli",
  "/citta/modena",
  "/citta/piacenza",
  "/citta/ferrara",
  "/account",
  "/account/settings",
  "/ask-me-anything",
];

export default function Navbar(props) {
  const [display, setDisplay] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setDisplay(routesWithNavbar.includes(window.location.pathname));
  }, [location.pathname]);

  //state for cities modal
  const [citaModal, setCitaModal] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false); // State to manage the menu open/close

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      setCitaModal(false);
    }
  };

  const handleCitaButtonClick = (event, elem) => {
    event.preventDefault(); // Prevent default behavior (page reload)
    // Your custom function here for the specific button with index 3
    if (elem.index === 3) {
      // Do something special for button with index 3
      console.log("Button with index 3 clicked!");
      console.log(citaModal);
      setCitaModal(!citaModal);
    } else {
      // Handle regular button click
      window.location.href = elem.slug; // or use your custom navigation logic
    }
  };

  // EXTRACT THE ROUTE FROM THE LINK FUNCTION
  function extractRoute() {
    if (location.pathname === "/") {
      return "Open Lab Digital";
    }
    if (location.pathname === "/maturita-digitale") {
      return "Maturità digitale";
    }
    if (location.pathname === "/citta/forli") {
      return "Forlì";
    }

    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    // Check if the last segment contains hyphens
    if (lastSegment.includes("-")) {
      // Split by hyphens, capitalize the first word, and keep the subsequent words in lowercase
      const words = lastSegment.split("-");
      const formattedSegment = words
        .map((word, index) =>
          index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
        )
        .join(" ");
      return formattedSegment;
    } else {
      // Capitalize the first letter
      const capitalizedSegment =
        lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
      return capitalizedSegment;
    }
  }

  const url = window.location.href;

  //border left for the city name container (0px if we are on any of the 4 cities page )
  const validCities = ["ferrara", "modena", "forli", "piacenza"];
  const city = url.split("/").pop(); // Extract the city from the URL

  //city colors

  function getColorForCity(url) {
    const city = url.split("/").pop(); // Extract the city from the URL
    let backgroundButton, borderButton;

    switch (city) {
      case "ferrara":
        backgroundButton = "var(--ferrara)";
        borderButton = "2px solid var(--ferrara-border)";
        break;
      case "modena":
        backgroundButton = "var(--modena)";
        borderButton = "2px solid var(--modena-border)";
        break;
      case "forli":
        backgroundButton = "var(--forli)";
        borderButton = "2px solid var(--forli-border)";
        break;
      case "piacenza":
        backgroundButton = "var(--piacenza)";
        borderButton = "2px solid var(--piacenza-border)";
        break;
      default:
        backgroundButton = "";
        borderButton = "";
        break;
    }

    return { backgroundButton, borderButton };
  }
  const [buttonStyles, setButtonStyles] = useState({
    background: "",
    border: "",
  });
  useEffect(() => {
    const { backgroundButton, borderButton } = getColorForCity(url);
    setButtonStyles({
      background: backgroundButton,
      border: borderButton,
    });
  }, []); // Run once on component mount

  return (
    display && (
      <>
        <div className={`navbar-container ${props.chiedi ? "font-black" : ""}`}>
          <div className="navbar">
            <div className="home">
              <a href="/">
                <div className="logo" style={buttonStyles}>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.92276 5.62528L9.5 1.25056L17.0772 5.62528V14.3747L9.5 18.7494L1.92276 14.3747V5.62528Z"
                      stroke="white"
                      strokeWidth="1.3"
                    />
                    <path
                      d="M9.50346 10.0002V19.3139M17.4201 14.5639L1.49365 5.43646M1.49365 14.5639L17.5133 5.43646M13.6946 7.67176L5.77797 3.01489"
                      stroke="white"
                      strokeWidth="1.3"
                    />
                    <path
                      d="M13.6021 7.67163V16.7059"
                      stroke="white"
                      strokeWidth="1.3"
                    />
                  </svg>
                </div>
              </a>
              <span
                className={`home-link ${validCities.includes(city) ? "border-left-0px" : ""
                  }`}
                style={buttonStyles}
              >
                <div className="route">{extractRoute()}</div>
              </span>
              <div className="vertical-line"> </div>
            </div>

            {/* -------------------- LINKS AND SEARCHBAR COMPONENT ↓ -------------------- */}
            <LinksAndSearchBar
              handleCitaButtonClick={handleCitaButtonClick}
              citaModal={citaModal}
              menuOpen={menuOpen}
            />

            <div className="login">
              <div className="vertical-line"> </div>

              {/* --------------------  MIO SPAZIO BUTTON (IF LOGGED IN) / LOGIN BUTTON (IF NOT LOGGED IN) ↓  -------------------- */}
              <AccountButton logged={!props.ifNotLoggedIn} />
              {/* -------------------- HAMBURGER BUTTON ↓ -------------------- */}

              <div className="hamburger" onClick={toggleMenu}>
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.8125 1.0625H13.1875M0.8125 5H13.1875M0.8125 8.9375H13.1875"
                    stroke="white"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              {/* -------------------- HAMBURGER MODALS (IF LOGGED IN/ IF NOT LOGGED IN) ↓ -------------------- */}

              {props.ifNotLoggedIn ? (
                <HamburgerModal
                  notLoggedIn
                  navbarButtons={navbarButtons}
                  menuOpen={menuOpen}
                  hamButtons={hamButtons}
                  // next 3 props are for the cities dropdown modal
                  handleCitaButtonClick={handleCitaButtonClick}
                  cities={cities}
                  citaModal={citaModal}
                  displayNoneForDesktop
                />
              ) : (
                <HamburgerModal
                  loggedIn
                  navbarButtons={navbarButtons}
                  menuOpen={menuOpen}
                  hamButtons={hamButtons}
                  // next 3 props are for the cities dropdown modal
                  handleCitaButtonClick={handleCitaButtonClick}
                  cities={cities}
                  citaModal={citaModal}
                  displayNoneForDesktop
                />
              )}

              {/* hamburger modal when logged out*/}
            </div>
          </div>
        </div>
      </>
    )
  );
}

function AccountButton({ logged }) {
  const userPicture = window.localStorage.getItem("userPicture");

  const loggedView = (
    <a href="/account" className="account-link">
      <div
        className="account-icon"
        style={{ backgroundImage: `url(${userPicture})` }}
      >
        {userPicture === "null" && icons.user}
        <div className="account-likes">{icons.heart}</div>
      </div>
    </a>
  );

  const guestView = (
    <a className="login-link" href="/login">
      <div className="login">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6563 4.75C11.6563 5.45448 11.3764 6.13011 10.8783 6.62825C10.3801 7.1264 9.70451 7.40625 9.00002 7.40625C8.29554 7.40625 7.61992 7.1264 7.12177 6.62825C6.62363 6.13011 6.34377 5.45448 6.34377 4.75C6.34377 4.04552 6.62363 3.36989 7.12177 2.87175C7.61992 2.3736 8.29554 2.09375 9.00002 2.09375C9.70451 2.09375 10.3801 2.3736 10.8783 2.87175C11.3764 3.36989 11.6563 4.04552 11.6563 4.75ZM3.68823 14.7503C3.71099 13.3565 4.28064 12.0275 5.27432 11.0499C6.26799 10.0724 7.60609 9.5245 9.00002 9.5245C10.394 9.5245 11.7321 10.0724 12.7257 11.0499C13.7194 12.0275 14.2891 13.3565 14.3118 14.7503C12.6454 15.5144 10.8333 15.9087 9.00002 15.9063C7.10452 15.9063 5.30536 15.4926 3.68823 14.7503Z"
            stroke="#0085FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </a>
  );

  return logged ? loggedView : guestView;
}

const icons = {
  heart: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
      />
    </svg>
  ),
  user: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
      />
    </svg>
  ),
};
