import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import { preventBodyScrolling } from '../utils/preventBodyScrolling';
import useFetch from "../utils/useFetch"

import { fetchChat, askChat, getAllChats, clearChat } from "../utils/customGPT";

import { marked } from 'marked'
import * as DOMPurify from 'dompurify'

import "../styles/miniChat.scss";

export default function MiniChat(props) {
  const [phase, setPhase] = useState("closed");

  const [sessionId, setSessionId] = useState(null);

  const [input, setInput] = useState(null);
  const [thinking, setThinking] = useState(null);

  const [messages, setMessages] = useState([]);

  const routesWithoutMiniChat = ["/login", "/ask-me-anything", "/register"];

  const userDataResponse = useFetch('/french/users/me')
  const userPicture = userDataResponse?.data?.data?.attributes?.profile_photo_url

  //where to appear the mini-chat
  const [display, setDisplay] = useState(null);
  const location = useLocation();
  useEffect(() => {
    setDisplay(!routesWithoutMiniChat.includes(window.location.pathname));
  }, [location.pathname]);



  return (
    display &&
    <>
      <div
        className="chat-overlay"
        style={{ opacity: phase === "open" ? 1 : 0, pointerEvents: phase === "open" ? "all" : "none" }}
        onClick={() => {
          setPhase("closed");
          preventBodyScrolling("unset")
        }}
      />
      <motion.div
        className={'mini-chat ' + 'mini-chat-' + phase}
        // variants={containerAnimation}
        // animate={phase}
        onMouseEnter={() => { phase !== 'open' && setPhase('hover') }}
        onMouseLeave={() => { phase !== 'open' && setPhase('closed') }}
        // onClick={() => { props.viewportWidth > 580 ? setPhase('open')  : window.location.replace('/ask-me-anything')}}
        onClick={() => {
          window.innerWidth > 580 ? (() => { setPhase('open'); preventBodyScrolling("hidden"); })() : window.location.replace('/ask-me-anything');
        }}

      >
        <div className='control-buttons'>
          <button className="close" onClick={(event) => {
            event.stopPropagation();
            // console.log("heeeeeeyyy tuuuu",props.viewportWidth);
            setPhase("closed");
            preventBodyScrolling("unset")
          }}>
            {icons.close}
          </button>
          <button className="expand" onClick={() => { window.location.replace('/ask-me-anything') }}>
            {icons.expand}
          </button>
        </div>
        {
          phase === 'open' && messages && (
            <div className="messages">
              {messages.map((message, id) => (
                <AnimatePresence key={id}>
                  <div className="message">
                    {
                      message.user_query &&
                      <motion.div
                        key={id + "_question"}
                        className='sent-container'
                      // transition = {{duration: 0.5}}
                      >
                        <p className="sent">
                          {message.user_query.replace('&apos;', "'")}
                        </p>
                        <div className="avatar" >
                          {
                            userPicture ?
                              <img src={userPicture} />
                              :
                              <div className="chat-avatar-default">
                                {icons.user}
                              </div>
                          }
                        </div>
                      </motion.div>
                    }
                    {
                      message.openai_response &&
                      <motion.div
                        key={id + "_answer"}
                        className="received-container"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1 }}
                      >
                        <div className="avatar chat-avatar">
                          {icons.logo}
                        </div>
                        <div className="received-mkd-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(message.openai_response)) }} />
                      </motion.div>
                    }
                  </div>
                </AnimatePresence>
              ))}
              <div style={{ width: "100%", height: "200px" }} />
            </div>
          )
        }
        <motion.div
          className={'input-container ' + 'input-container-' + phase}
        >
          <MiniChatInputContainer
            phase={phase}
            sessionId={sessionId} setSessionId={setSessionId.bind(this)}
            input={input} setInput={setInput.bind(this)}
            messages={messages} setMessages={setMessages.bind(this)}
            thinking={thinking} setThinking={setThinking.bind(this)}
          />
        </motion.div>
      </motion.div>
    </>
  )
}

function MiniChatInputContainer({
  phase,
  sessionId,
  setSessionId,
  input,
  setInput,
  messages,
  setMessages,
  thinking,
  setThinking,
}) {
  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  // useEffect(() => {
  //   if (phase !== "open") {
  //     return;
  //   }

  //   const handleEnterKey = (event) => {
  //     if (event.key === "Enter") {
  //       buttonRef.current.click();
  //     }
  //   };

  //   inputRef.current.addEventListener("keydown", handleEnterKey);



  // }, [phase]);

  useEffect(() => {
    if (phase !== "open") {
      return () => { }; // No action needed when phase changes and is not 'open'
    }

    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        buttonRef.current.click();
      }
    };

    const inputRefCurrent = inputRef.current;

    inputRefCurrent.addEventListener("keydown", handleEnterKey);

    return () => {
      // Clean up the event listener when the component unmounts or 'phase' changes
      inputRefCurrent.removeEventListener("keydown", handleEnterKey);
    };
  }, [phase]);


  switch (phase) {
    case "closed":
      return (
        <>
          {icons.logo}
          <p className="tag">Ask me anything</p>
        </>
      );
    case "hover":
      return (
        <>
          {icons.logo}
          <p className="tag">Ask me anything</p>
        </>
      );
    case "open":
      return (
        <>
          <input
            type="text"
            id="chat-input"
            placeholder="Chiedi..."
            onChange={(e) => {
              setInput(e.target.value);
            }}
            ref={inputRef}
          />
          <button
            ref={buttonRef}
            disabled={thinking}
            className={"send send_" + thinking}
            onClick={() => {
              askChat(
                sessionId,
                setSessionId,
                input,
                inputRef,
                messages,
                setMessages,
                setThinking
              );
            }}
          >
            {icons[thinking ? "dots" : "arrow"]}
            {!thinking && <p>Enter</p>}
          </button>
        </>
      );
  }
}

const icons = {
  user: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke- width="1.5" stroke="#fff" class="w-6 h-6" >
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
    </svg >
  ),
  logo: (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.92276 5.62528L9.5 1.25056L17.0772 5.62528V14.3747L9.5 18.7494L1.92276 14.3747V5.62528Z"
        stroke="white"
        stroke-width="1.3"
      />
      <path
        d="M9.50346 10.0002V19.3139M17.4201 14.5639L1.49365 5.43646M1.49365 14.5639L17.5133 5.43646M13.6946 7.67176L5.77797 3.01489"
        stroke="white"
        stroke-width="1.3"
      />
      <path d="M13.6021 7.67163V16.7059" stroke="white" stroke-width="1.3" />
    </svg>
  ),
  close: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
  ),
  expand: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
    </svg>
  )
}

