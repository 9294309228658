//PACKS
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

//STYLES
import "../styles/hamburgerModal.scss";
import CitiesDropdown from "./CitiesDropdown";

const HamburgerModal = ({ displayNoneForDesktop, menuOpen, hamButtons, navbarButtons, notLoggedIn, loggedIn, handleCitaButtonClick, cities, citaModal }) => {
  return (
    <>
      {notLoggedIn && (
        <AnimatePresence>
          {menuOpen && (
            <motion.div
              className="menu"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <div className="rest-of-pages" style={{ marginTop: "0px" }}>
                {hamButtons.slice(1, 3).map((button, index) => (
                  <a href={button.slug} key={index}>
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))}
              </div>

              {/* homepage links: calendario, video, le citta, homepage */}

              <div className="rest-of-pages noDesktop" style={{ marginTop: "0px" }}>
                {navbarButtons.map((button, index) => (
                  <a
                    href={button.index === 3 ? "" : button.slug}
                    key={index}
                    onClick={(event) => handleCitaButtonClick(event, button)}
                  >
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))}
              </div>
              <div className="not-logged-in-cities-modal">
                <CitiesDropdown displayNoneForDesktop cities={cities} citaModal={citaModal} />
              </div>



              <div className="log-out">
                {hamButtons.slice(4).map((button, index) => (
                  <a
                    href="/login"
                    key={index}
                    style={{ background: "#0085FF" }}
                  >
                    Log in
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8125 6.75V3.9375C11.8125 3.48995 11.6347 3.06072 11.3182 2.74426C11.0018 2.42779 10.5726 2.25 10.125 2.25H5.625C5.17745 2.25 4.74823 2.42779 4.43176 2.74426C4.11529 3.06072 3.9375 3.48995 3.9375 3.9375V14.0625C3.9375 14.5101 4.11529 14.9393 4.43176 15.2557C4.74823 15.5722 5.17745 15.75 5.625 15.75H10.125C10.5726 15.75 11.0018 15.5722 11.3182 15.2557C11.6347 14.9393 11.8125 14.5101 11.8125 14.0625V11.25M9 6.75L6.75 9M6.75 9L9 11.25M6.75 9H16.3125"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}

      {loggedIn && (
        <AnimatePresence>
          {menuOpen && (
            <motion.div
              className="menu"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <div className="mio-spazio-ham">
                {hamButtons.slice(0, 1).map((button, index) => (
                  <a href={button.slug} key={index}>
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))}
              </div>
              <div className="rest-of-pages">
                {hamButtons.slice(1, 3).map((button, index) => (
                  <a href={button.slug} key={index}>
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))}
              

                {/* impostazioni  button*/}
   {/*  <div className="ham-hor-line"></div>
              {hamButtons.slice(3, 4).map((button, index) => (
                  <a href={button.slug} key={index}>
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))} */}
              </div>


              {/* homepage links: calendario, video, le citta, homepage */}
              <div className="rest-of-pages noDesktop" style={{ marginTop: "0px" }}>
                {navbarButtons.map((button, index) => (
                  <a
                    onClick={(event) => handleCitaButtonClick(event, button)}
                    href={button.index === 3 ? "" : button.slug}
                    key={index}>
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))}
              </div>
              <div className="logged-in-cities-modal">
                <CitiesDropdown displayNoneForDesktop cities={cities} citaModal={citaModal} />
              </div>


              <div className="log-out">
                {hamButtons.slice(4).map((button, index) => (
                  <a href={'/login'} key={index}>
                    <span> {button.title} </span>
                    {button.icon}
                  </a>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default HamburgerModal;
