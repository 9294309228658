import axiosInstance from '../utils/api';

export default async function login(e, setError) {

  e.preventDefault();

  const username = e.target.username.value
  const password = e.target.password.value

  try {

    const response = await axiosInstance.post('/auth/sign_in',
      {
        "email": username,
        "password": password
      }
    );

    const { "access-token": accessToken } = response.headers
    const { "client": client } = response.headers
    const { "uid": uid } = response.headers

    const storeTokenPromise = new Promise((resolve, reject) => {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('client', client);
      localStorage.setItem('uid', uid);
      resolve();
    });

    if (e.target.remember_me.value) {
      console.log('trying to remember')
      storeTokenPromise
        .then((result) => {
          window.location.href = '/'
        })
        .catch((error) => {
          console.log(error)
        });
    } else {
      window.location.href = '/'
    }

  } catch (err) {
    const error = err?.response?.data?.errors
    if (error) {
      setError({ status: true, fields: error })
    } else {
      setError({ status: true, fields: ["Somethig went wrong"] })
    }

  }

}
