//PACKS
import React from "react";

//STYLES
import "../styles/seeMoreBanner.scss";

//ASSETS
import calendar from "../assets/images/banner-calendar.svg";
import video from "../assets/images/banner-video.jpg";
import autovalutazione from "../assets/images/banner-autovalutazione.jpg";

const SeeMoreBanner = (props) => {

  return (
    <div className="seeMoreBanner-container">
      {props.calendario && (
        <>
          <div className="left-side">
            <div className="content">
              <p className="title"> Scopri il nostro calendario</p>
              <p className="subtitle">
                Visualizza tutte le attività e gli eventi in programmazione nei Laboratori Aperti di Modena, Piacenza, Ferrara e Forlì.
              </p>
            </div>
            <a href="/calendario">
              <div className="button">
                <span> Vai al calendario </span>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.15625 4.3125L9.84375 9L5.15625 13.6875"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div className="right-side">
            <img src={calendar} />
            <div className="shadow" />
          </div>
        </>
      )}
      {props.video && (
        <>
          <div className="left-side">
            <div className="content">
              <p className="title"> Scopri i nostri video </p>
              <p className="subtitle">
                Sfoglia il nostro catalogo video che racconta e approfondisce la quotidianità dei Laboratori Aperti.
              </p>
            </div>
            <a href="/video">
              <div className="button">
                <span> Vai ai video </span>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.15625 4.3125L9.84375 9L5.15625 13.6875"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div className="right-side">
            <div className="shadow" />
            <img src={video} />
          </div>
        </>
      )}
      {props.autovalutazione && (
        <>
          <div className="left-side">
            <div className="content">
              <p className="title">
                {" "}
                Sei pronto a misurare la tua maturità digitale?
              </p>
              <p className="subtitle">
                Bevenuto! Supportiamo imprese, istituzioni, Pubblica Amministrazione in progetti di trasformazione digitale e crescita. I nostri strumenti guardano a competenze, processi, collaborazioni per gestire il cambiamento.
              </p>
            </div>
            <a href="/maturita-digitale">
              <div className="button">
                <span> Misura la tua maturità digitale </span>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.15625 4.3125L9.84375 9L5.15625 13.6875"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div className="right-side">
            <img src={autovalutazione} />
            <div className="shadow" />
          </div>
        </>
      )}
    </div>
  );
};

export default SeeMoreBanner;
