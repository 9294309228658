import React, { memo, useState, useEffect } from "react";
import bannerImage from "../assets/images/banner-autovalutazione.jpg"
import "../styles/autovalutazioneStackedCard.scss"

<svg
  width="25"
  height="40"
  viewBox="0 0 25 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.756693 39.666C-0.0535253 39.5162 -0.256121 38.4864 0.359539 37.9388C5.30204 33.5428 8.41556 27.135 8.41556 20C8.41556 12.865 5.30204 6.45721 0.359538 2.06116C-0.256121 1.51357 -0.0535275 0.483776 0.75669 0.333975C1.94301 0.114639 3.16604 0 4.41585 0C15.4615 0 24.4158 8.9543 24.4158 20C24.4158 31.0457 15.4615 40 4.41585 40C3.16604 40 1.94301 39.8854 0.756693 39.666Z"
    fill="black"
    fillOpacity="0.2"
  />
</svg>;

const AutovalutazioneStackedCard = (props) => {

  return (
    <>
      <div className="autovalutazioneStacked-card" style={{ backgroundImage: `url(${bannerImage})` }} >
        <div className="blur-top"> </div>
        <div className="content">
          <p className="title">Sei pronto a misurare la tua maturità digitale?
          </p>
        </div>
        <div className="buttons">
          <a href="https://www.readytodigital.it/" target="_blank">
            <div className="button ora">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" class="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                </svg>
              </div>
              <span> Visita il sito del Ready to Digital</span>
            </div>
          </a>

        </div>
      </div>
    </>
  );
};

export default memo(AutovalutazioneStackedCard);
