import React, { useEffect } from 'react'
import "../styles/signupModal.scss"
import { Link } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy';

export default function SingupModal(props) {

  const signupModal = useStoreState((state) => state.signupModal);
  const setSignupModal = useStoreActions((actions) => actions.setSignupModal);

  return (
    signupModal && <>
      <div className="signup-modal-shadow" onClick={() => { setSignupModal(false); }} />
      <div className='signup-modal'>
        <div className="texts">
          <p className="title">
            You're not logged in
          </p>
          <p className="description">
            In order to like content or add events to your Open Labs calendar you'll need to log into your account.
          </p>
          <p className="description">
            You can also chose to stay signed off and enjoy the content Open Lab Digital provides.
          </p>
        </div>
        <div className="buttons">
          <button className="btn btn-secondary" onClick={() => { setSignupModal(false) }}>
            <p>Stay logged off</p>
          </button>
          <Link to='/register'>
            <button className="btn btn-primary" onClick={() => { setSignupModal(false) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              <p>Signup</p>
            </button>
          </Link>
        </div>
      </div >
    </>
  )
}
