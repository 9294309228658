//PACKS
import React, { useEffect } from "react";

//STYLES
import "../../styles/carouselsCityGrid.scss";

//COMPONENTS
import videoCards from "../../assets/jsons/videoCardsContent.json";
import CalendarCard from "../CalendarCards/CalendarCard";
import CityPageStackedCard from "../CityPageStackedCard";
import CityPageVerticalSlider from "../CityPageVerticalSlider";
import EventCardsCarouselCityPage from "./EventCardsCarouselCityPage";

import "../../assets/images/coworking_ferrara_1.jpeg"

import { ferraraImages, forliImages, modenaImages, piacenzaImages } from "../../utils/coworkingImages"


const CarouselsCityGrid = ({ calendarCards, cityName, cities }) => {


  // Determines the index of a city in the cityImages data array based on the cityName provided.
  // If 'Forlì' is the cityName, the index is found using the 'forli' slug. Otherwise, it's matched by the cityName's slug.


  const cityIndex =
    cityName === "forlì"
      ? cities?.findIndex((city) => city.attributes.slug === "forli")
      : cities?.findIndex(
        (city) =>
          city?.attributes?.slug === cityName
      );

  return (
    <>
      <div className="carouselsGrid-container">
        <div className="vertical-carousel-container">
          <div className="ver-carousel-title">
            <div className="bg">
              <span> Spazi di coworking di {cityName && cityName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} </span>
            </div>
          </div>

          {/* <HomepageVerticalSlider content={dummyActivityCardsOne.cards} /> */}

          <CityPageVerticalSlider
            content={cities}
            cityName={cityName}
            cityIndex={cityIndex}
          />
        </div>
        <div className="vertical-bare-container">
          <div className="vertical-bare"> </div>
        </div>
        <div className="horisontal-carousel-container">
          <div className="hor-carousel-title">
            <div className="bg">
              {" "}
              <span> Prossimi eventi in {cityName && cityName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
            </div>
          </div>
          <div className="calendar-cards">
            {calendarCards?.map((activity, id) => (
              <CalendarCard location={'citypage'} activity={activity} key={id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselsCityGrid;

// const cityImages= {
//   data: [
//     {
//       id: "1",
//       type: "activity_organizer",
//       attributes: {
//         name: "Castelnuovo Placemaking Hub",
//         description: "",
//         slug: "castelnuovo",
//         cover_pict: null,
//         cover_video: null,
//         coworking_picts: [],
//         selection_videos: []
//       }
//     },
//     {
//       id: "3",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Ferrara",
//         description: "",
//         slug: "ferrara",
//         cover_pict: "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBZdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6ec65b24c17c7e888231ec1d73c4a628f72b7bab/coworking_ferrara_2.jpeg",
//         cover_video: null,
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBZdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6ec65b24c17c7e888231ec1d73c4a628f72b7bab/coworking_ferrara_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBaZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--34b7fdded6c1d36e54041d0c19730cdc45442a04/coworking_ferrara_3.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBhUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--93b82d1a1234fcc68447b27dc0a1be1b0d71d51f/coworking_ferrara_4.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBKQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f8006e5888cdbc5a47c73c2a4e8b0bdf50f07729/Spazi%20di%20Coworking%20-%20Laboratorio%20Aperto%20di%20Ferrara.jpg"
//         ],
//         selection_videos: []
//       }
//     },
//     {
//       id: "5",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Forlì",
//         description: "",
//         slug: "forli",
//         cover_pict: "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBaZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--34b7fdded6c1d36e54041d0c19730cdc45442a04/coworking_ferrara_3.jpeg",
//         cover_video: "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBQUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--682f3eb38e7237cef313451ed602000f449b33e4/LA_Inaugurazione.mp4",
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWVk9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1af5bc15ea50dece1fbd5a29ef50e1a362679d13/coworking_forli_4.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWHM9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1aecab0176f43a0050a78b9dd37e7ef012d61c09/coworking_forli_1.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWDQ9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--18cace17a6c791bb73c320499c0c758b4bb078d6/coworking_forli_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWUU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--60277c3a57dbf5c4cbce388a52569c5373b79df0/coworking_forli_3.jpeg"
//         ],
//         selection_videos: []
//       }
//     },
//     {
//       id: "2",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Modena",
//         description: "",
//         slug: "modena",
//         cover_pict: "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBkZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c80305acd2a43601b57dc802c6191c6932d8de53/coworking_modena_2.jpeg",
//         cover_video: "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2f3aaddf7b581cac08c16b0358042a5c538a80e4/Python_Barozzi.mp4",
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBkZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c80305acd2a43601b57dc802c6191c6932d8de53/coworking_modena_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBLQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8805bac5111b99d6bed6be5175b26484ebc0f7b0/Spazi%20di%20Coworking%20-%20Laboratorio%20Aperto%20di%20Modena.jpg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBlUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--894666d3369c6ce0b7d1f44a88d3a094f868bde6/coworking_modena_3.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBmUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--263ca6896ac5e9ce47bc618b72a9af34fb205cc1/coworking_modena_4.jpeg"
//         ],
//         selection_videos: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2f3aaddf7b581cac08c16b0358042a5c538a80e4/Python_Barozzi.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b44c1b2f4baa2ae58dcb1f99f015b3eef1106558/Clap.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBPQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b03120332c4c9a3587279c2266897f030e1bb153/Festival%20della%20sostenibilit%C3%A0.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBPZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--22af895925d191e7f00d591f84cbf594d959de88/Illusion.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBPdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--646169244156d22bc591ce32e163e43587667b86/Smart_life.mp4"
//         ]
//       }
//     },
//     {
//       id: "4",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Piacenza",
//         description: "",
//         slug: "piacenza",
//         cover_pict: "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWUU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--60277c3a57dbf5c4cbce388a52569c5373b79df0/coworking_forli_3.jpeg",
//         cover_video: null,
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6abd4ebcabac61ecf43b9eb23c61d2a9a982ffa9/Spazi%20di%20Coworking%20-%20Laboratorio%20Aperto%20di%20Piacenza.JPG",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBiQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c68eedfd3b5f7f78929f682903fb9ea06ff33cd9/coworking_piacenza_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBjZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1b3cdfddf77e72ebb15d87e021fd615d7630944f/coworking_piacenza_4.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBidz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--191670563c0e38fd62a8fcba45a2262b62d360cd/coworking_piacenza_3.jpeg"
//         ],
//         selection_videos: []
//       }
//     }
//   ],
//   meta: {
//     total: 5
//   }
// }



// const content = [
//   {
//     index: 0,
//     city: "forli",
//     images: forliImages,
//   },
//   {
//     index: 1,
//     city: "modena",
//     images: modenaImages,
//   },
//   {
//     index: 2,
//     city: "ferrara",
//     images: ferraraImages,
//   },
//   {
//     index: 3,
//     city: "piacenza",
//     images: piacenzaImages,
//   },
// ];



// const content = [
//   {
//     index: 0,
//     city: "forli",
//     images: generateCityImages("forli", 4),
//   },
//   {
//     index: 1,
//     city: "modena",
//     images: generateCityImages("modena", 4),
//   },
//   {
//     index: 2,
//     city: "ferrara",
//     images: generateCityImages("ferrara", 4),
//   },
//   {
//     index: 3,
//     city: "piacenza",
//     images: generateCityImages("piacenza", 4),
//   },
// ];

// function generateCityImages(city, count) {
//   const images = [];
//   for (let i = 1; i <= count; i++) {
//     const imageUrl = `../../assets/images/coworking_${city}_${i}.jpeg`;
//     // const link = `https://example.com/${city}/image${i}`;
//     const link = `https://laboratorioaperto${city}.it/gli-spazi/`
//     const title = `${city}`
//     images.push({ imageUrl, link, title });
//   }
//   return images;
// }

// const dummyCalendar =

// {
//   activities: [
//     {
//       "id": 0,
//       "title1": "Vox Happening",
//       "title2": "On The Go 1",
//       "organisation": "Apple Inc.",
//       "location": "Modena, EX AEM",
//       "hours": "12 Oct 08:30 - 12:00"
//     },
//     {
//       "id": 1,
//       "title1": "Vox Happening",
//       "title2": "On The Go 2",
//       "organisation": "Apple Inc.",
//       "location": "Modena, EX AEM",
//       "hours": "12 Oct 08:30 - 12:00"
//     }

//   ]
// }
