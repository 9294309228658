export function injectMeta(title, description, url_to_image) {

  facebookMeta(title, description, url_to_image)
  // twitterMeta(title, description, link)

}

function facebookMeta(title, description, url_to_image) {

  const facebookTitle = document.createElement('meta')
  facebookTitle.setAttribute('property', 'og:title')
  facebookTitle.content = "Open Lab Digital - " + title

  const facebookDescription = document.createElement('meta')
  facebookDescription.setAttribute('property', 'og:description')
  facebookDescription.content = description

  const facebookURL = document.createElement('meta')
  facebookURL.setAttribute('property', 'og:url')
  facebookURL.content = window.location.href || "https://openlabdigital.com"

  const facebookImage = document.createElement('meta')
  facebookImage.setAttribute('property', 'og:image')
  facebookImage.content = url_to_image


  removeMeta();

  document.head.appendChild(facebookTitle);
  document.head.appendChild(facebookDescription);
  document.head.appendChild(facebookURL);
  document.head.appendChild(facebookImage);

}

function twitterMeta(title, description, url_to_image) {

  const twitterTitle = document.createElement('meta')
  twitterTitle.property = 'twitter:title'
  twitterTitle.content = "Open Lab Digital - " + title

  const twitterDescription = document.createElement('meta')
  twitterDescription.property = 'twitter:description'
  twitterDescription.content = description

  const twitterImage = document.createElement('meta')
  twitterImage.property = 'twitter:image'
  twitterImage.content = url_to_image

  document.head.appendChild(twitterTitle);
  document.head.appendChild(twitterDescription);
  document.head.appendChild(twitterImage);

}

function removeMeta() {

  ['og:title', 'og:description', 'og:url', 'og:image', 'twitter:title', 'twitter:description', 'twitter:image'].forEach((s)=>{
    const list = document.querySelectorAll(`meta[property="${s}"]`)
    if (list.length > 0) {
      list.forEach((e)=>{e.remove();})
    }
  })
}
