export const hamButtons = [
  {
    title: "Mio Spazio",
    slug: "/account",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8125 4.5C11.8125 5.24592 11.5162 5.96129 10.9887 6.48874C10.4613 7.01618 9.7459 7.3125 8.99998 7.3125C8.25406 7.3125 7.53869 7.01618 7.01124 6.48874C6.4838 5.96129 6.18748 5.24592 6.18748 4.5C6.18748 3.75408 6.4838 3.03871 7.01124 2.51126C7.53869 1.98382 8.25406 1.6875 8.99998 1.6875C9.7459 1.6875 10.4613 1.98382 10.9887 2.51126C11.5162 3.03871 11.8125 3.75408 11.8125 4.5ZM3.37573 15.0885C3.39983 13.6128 4.00299 12.2056 5.05512 11.1705C6.10724 10.1354 7.52405 9.55535 8.99998 9.55535C10.4759 9.55535 11.8927 10.1354 12.9448 11.1705C13.997 12.2056 14.6001 13.6128 14.6242 15.0885C12.8598 15.8976 10.9411 16.3151 8.99998 16.3125C6.99298 16.3125 5.08798 15.8745 3.37573 15.0885Z"
          stroke="white"
          stroke-opacity="0.7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    background:
      "https://images.unsplash.com/photo-1635488640163-e5f6782cda6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80",
  },
  {
    title: "Ask me anything",
    slug: "/ask-me-anything",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6875 9.57C1.6875 10.77 2.52975 11.8155 3.71775 11.9903C4.51875 12.108 5.32875 12.1995 6.14625 12.2633C6.49575 12.291 6.816 12.474 7.011 12.7665L9 15.75L10.989 12.7665C11.184 12.474 11.5043 12.291 11.8538 12.264C12.6713 12.1995 13.4812 12.108 14.2822 11.9903C15.4702 11.8155 16.3125 10.7708 16.3125 9.56925V5.05575C16.3125 3.85425 15.4702 2.8095 14.2822 2.63475C12.5332 2.37804 10.7678 2.24944 9 2.25C7.206 2.25 5.442 2.38125 3.71775 2.63475C2.52975 2.8095 1.6875 3.855 1.6875 5.05575V9.56925V9.57Z"
          stroke="white"
          strokeOpacity="0.7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Maturità digitale",
    slug: "/maturita-digitale",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.625 10.6875V8.71875C14.625 8.04742 14.3583 7.40359 13.8836 6.92889C13.4089 6.45418 12.7651 6.1875 12.0938 6.1875H10.9688C10.745 6.1875 10.5304 6.09861 10.3721 5.94037C10.2139 5.78214 10.125 5.56753 10.125 5.34375V4.21875C10.125 3.54742 9.85832 2.90359 9.38361 2.42889C8.90891 1.95418 8.26508 1.6875 7.59375 1.6875H6.1875M6.1875 11.25H11.8125M6.1875 13.5H9M7.875 1.6875H4.21875C3.753 1.6875 3.375 2.0655 3.375 2.53125V15.4688C3.375 15.9345 3.753 16.3125 4.21875 16.3125H13.7812C14.247 16.3125 14.625 15.9345 14.625 15.4688V8.4375C14.625 6.64729 13.9138 4.9304 12.648 3.66453C11.3821 2.39866 9.66521 1.6875 7.875 1.6875Z"
          stroke="white"
          strokeOpacity="0.7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Impostazioni",
    slug: "/autovalutazione",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.19529 2.955C7.26279 2.5485 7.61529 2.25 8.02779 2.25H9.97254C10.385 2.25 10.7375 2.5485 10.805 2.955L10.9648 3.91575C11.012 4.19625 11.1995 4.43025 11.4485 4.56825C11.504 4.59825 11.5588 4.6305 11.6135 4.6635C11.8565 4.8105 12.1535 4.85625 12.4198 4.7565L13.3325 4.4145C13.5196 4.34416 13.7255 4.34248 13.9137 4.40978C14.1019 4.47708 14.26 4.60899 14.36 4.782L15.332 6.46725C15.4318 6.64028 15.467 6.8431 15.4313 7.03962C15.3956 7.23615 15.2913 7.41363 15.137 7.5405L14.3848 8.16075C14.165 8.34075 14.0563 8.6205 14.0615 8.90475C14.0627 8.96849 14.0627 9.03226 14.0615 9.096C14.0563 9.3795 14.165 9.6585 14.384 9.8385L15.1378 10.4595C15.4558 10.722 15.5383 11.175 15.3328 11.532L14.3593 13.2172C14.2594 13.3902 14.1014 13.5222 13.9134 13.5896C13.7254 13.657 13.5196 13.6556 13.3325 13.5855L12.4198 13.2435C12.1535 13.1438 11.8573 13.1895 11.6128 13.3365C11.5584 13.3696 11.5034 13.4016 11.4478 13.4325C11.1995 13.5697 11.012 13.8037 10.9648 14.0842L10.805 15.0443C10.7375 15.4515 10.385 15.75 9.97254 15.75H8.02704C7.61454 15.75 7.26204 15.4515 7.19454 15.045L7.03479 14.0842C6.98829 13.8037 6.80079 13.5698 6.55179 13.4318C6.49617 13.4011 6.44116 13.3693 6.38679 13.3365C6.14304 13.1895 5.84679 13.1438 5.57979 13.2435L4.66704 13.5855C4.48008 13.6556 4.27431 13.6572 4.08631 13.5899C3.89831 13.5226 3.74027 13.3908 3.64029 13.218L2.66754 11.5327C2.56775 11.3597 2.53257 11.1569 2.56828 10.9604C2.60399 10.7639 2.70826 10.5864 2.86254 10.4595L3.61554 9.83925C3.83454 9.65925 3.94329 9.3795 3.93804 9.09525C3.93687 9.03151 3.93687 8.96774 3.93804 8.904C3.94329 8.6205 3.83454 8.3415 3.61554 8.1615L2.86254 7.5405C2.70844 7.41367 2.60429 7.23633 2.56859 7.03997C2.53289 6.84361 2.56795 6.64095 2.66754 6.468L3.64029 4.78275C3.74018 4.6096 3.8983 4.47754 4.08647 4.4101C4.27465 4.34266 4.48065 4.34422 4.66779 4.4145L5.57979 4.7565C5.84679 4.85625 6.14304 4.8105 6.38679 4.6635C6.44079 4.6305 6.49629 4.59825 6.55179 4.5675C6.80079 4.43025 6.98829 4.19625 7.03479 3.91575L7.19529 2.955Z"
          stroke="white"
          strokeOpacity="0.7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25C8.40326 11.25 7.83097 11.0129 7.40901 10.591C6.98705 10.169 6.75 9.59674 6.75 9C6.75 8.40326 6.98705 7.83097 7.40901 7.40901C7.83097 6.98705 8.40326 6.75 9 6.75C9.59674 6.75 10.169 6.98705 10.591 7.40901C11.0129 7.83097 11.25 8.40326 11.25 9Z"
          stroke="white"
          strokeOpacity="0.7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Log out",
    slug: "/autovalutazione",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8125 6.75V3.9375C11.8125 3.48995 11.6347 3.06072 11.3182 2.74426C11.0018 2.42779 10.5726 2.25 10.125 2.25H5.625C5.17745 2.25 4.74823 2.42779 4.43176 2.74426C4.11529 3.06072 3.9375 3.48995 3.9375 3.9375V14.0625C3.9375 14.5101 4.11529 14.9393 4.43176 15.2557C4.74823 15.5722 5.17745 15.75 5.625 15.75H10.125C10.5726 15.75 11.0018 15.5722 11.3182 15.2557C11.6347 14.9393 11.8125 14.5101 11.8125 14.0625V11.25M14.0625 11.25L16.3125 9M16.3125 9L14.0625 6.75M16.3125 9H6.75"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];

// ---------------------------------------- DO NOT CHANGE THE INDEXES ----------------------------------------
export const navbarButtons = [
  {
    index: 0,
    title: "Home",
    slug: "/",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6875 8.99999L8.403 2.28374C8.733 1.95449 9.267 1.95449 9.59625 2.28374L16.3125 8.99999M3.375 7.31249V14.9062C3.375 15.372 3.753 15.75 4.21875 15.75H7.3125V12.0937C7.3125 11.628 7.6905 11.25 8.15625 11.25H9.84375C10.3095 11.25 10.6875 11.628 10.6875 12.0937V15.75H13.7812C14.247 15.75 14.625 15.372 14.625 14.9062V7.31249M6.1875 15.75H12.375"
          stroke="white"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    index: 1,
    title: "Calendario",
    slug: "/calendario",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.0625 2.25V3.9375M12.9375 2.25V3.9375M2.25 14.0625V5.625C2.25 5.17745 2.42779 4.74823 2.74426 4.43176C3.06072 4.11529 3.48995 3.9375 3.9375 3.9375H14.0625C14.5101 3.9375 14.9393 4.11529 15.2557 4.43176C15.5722 4.74823 15.75 5.17745 15.75 5.625V14.0625M2.25 14.0625C2.25 14.5101 2.42779 14.9393 2.74426 15.2557C3.06072 15.5722 3.48995 15.75 3.9375 15.75H14.0625C14.5101 15.75 14.9393 15.5722 15.2557 15.2557C15.5722 14.9393 15.75 14.5101 15.75 14.0625M2.25 14.0625V8.4375C2.25 7.98995 2.42779 7.56073 2.74426 7.24426C3.06072 6.92779 3.48995 6.75 3.9375 6.75H14.0625C14.5101 6.75 14.9393 6.92779 15.2557 7.24426C15.5722 7.56073 15.75 7.98995 15.75 8.4375V14.0625"
          stroke="white"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    index: 2,
    title: "Video",
    slug: "/video",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8125 7.875L15.3525 4.335C15.4312 4.25643 15.5313 4.20294 15.6404 4.18128C15.7494 4.15961 15.8625 4.17075 15.9652 4.21328C16.0679 4.25582 16.1557 4.32784 16.2175 4.42025C16.2794 4.51266 16.3124 4.62132 16.3125 4.7325V13.2675C16.3124 13.3787 16.2794 13.4873 16.2175 13.5797C16.1557 13.6722 16.0679 13.7442 15.9652 13.7867C15.8625 13.8292 15.7494 13.8404 15.6404 13.8187C15.5313 13.7971 15.4312 13.7436 15.3525 13.665L11.8125 10.125M3.375 14.0625H10.125C10.5726 14.0625 11.0018 13.8847 11.3182 13.5682C11.6347 13.2518 11.8125 12.8226 11.8125 12.375V5.625C11.8125 5.17745 11.6347 4.74823 11.3182 4.43176C11.0018 4.11529 10.5726 3.9375 10.125 3.9375H3.375C2.92745 3.9375 2.49822 4.11529 2.18176 4.43176C1.86529 4.74823 1.6875 5.17745 1.6875 5.625V12.375C1.6875 12.8226 1.86529 13.2518 2.18176 13.5682C2.49822 13.8847 2.92745 14.0625 3.375 14.0625Z"
          stroke="white"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    ),
  },
  {
    index: 3,
    title: "Le città",
    // slug: `/citta/`,
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6875 15.75H16.3125M2.8125 2.25V15.75M10.6875 2.25V15.75M15.1875 5.625V15.75M5.0625 5.0625H5.625M5.0625 7.3125H5.625M5.0625 9.5625H5.625M7.875 5.0625H8.4375M7.875 7.3125H8.4375M7.875 9.5625H8.4375M5.0625 15.75V13.2188C5.0625 12.753 5.4405 12.375 5.90625 12.375H7.59375C8.0595 12.375 8.4375 12.753 8.4375 13.2188V15.75M2.25 2.25H11.25M10.6875 5.625H15.75M12.9375 8.4375H12.9435V8.4435H12.9375V8.4375ZM12.9375 10.6875H12.9435V10.6935H12.9375V10.6875ZM12.9375 12.9375H12.9435V12.9435H12.9375V12.9375Z"
          stroke="white"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    index: 4,
    title: "Education",
    slug: `/education`,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
      </svg>
    ),
  },
];

export const cities = [
  {
    index: 0,
    cityName: "Modena",
    slug: "/citta/modena",
  },
  {
    index: 1,
    cityName: "Ferrara",
    slug: "/citta/ferrara",
  },
  {
    index: 2,
    cityName: "Piacenza",
    slug: "/citta/piacenza",
  },
  {
    index: 3,
    cityName: "Forlì",
    slug: "/citta/forli",
  },
];
