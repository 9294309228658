//UTILS
import axiosInstance from "./api"
import validateFields from "./validateFields"

const headers = {
  "client": localStorage.getItem('client'),
  "access-token": localStorage.getItem('access-token'),
  "uid": localStorage.getItem('uid'),
}

export async function handleGeneralForm(e, setError) {

  const fields = validateFields(e)

  if (fields.valid) {
    try {
      await axiosInstance.patch('/french/users', fields.body, { headers })
    }
    catch (err) {
      console.log(err)
    }
  } else {
    e.preventDefault()
    setError({ status: true, fields: fields.errorFields })
  }

}

export async function handlePasswordChange(e, setError) {

  e.preventDefault()

  const current_password = e.target.current_password.value
  const new_password = e.target.new_password.value
  const confirm_password = e.target.confirm_password.value

  const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+-=|\\]).{8,32}$/;

  if (new_password !== confirm_password) {
    e.preventDefault()
    setError({ status: true, fields: ["La nuova password e la conferma della password devono corrispondere."] })
    return
  }

  if (!pattern.test(new_password)) {
    e.preventDefault()
    setError({ status: true, fields: ["Questo modello di espressione regolare valida le password che contengono almeno una cifra, una lettera minuscola, una lettera maiuscola, un carattere speciale e hanno una lunghezza compresa tra 8 e 32 caratteri."] })
    return
  }

  const body = {
    current_password: current_password,
    password: new_password,
    password_confirmation: confirm_password
  }

  try {
    await axiosInstance.put('/auth/password', body, { headers })
  }
  catch (err) {
    setError({ status: true, fields: [err.message] })
    console.log(err)
  }


}

export async function handleDelete(e) {
  e.preventDefault()
  try {
    await axiosInstance.delete('/auth/sign_out', { headers })
    window.location.href = '/'
  }
  catch (err) {
    // setError({ status: true, fields: [err.message] })
    console.log(err)
  }
}