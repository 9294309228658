//PACKS
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { setModal } from "../utils/setModal";

//STYLES
import "../styles/contentCard.scss"

//COMPONENTS
import HalfMoonImages from './HalfMoonImages'
import CardActions from './CardActions'

import { redirectToCalendar } from "../utils/redirectToCalendar.js"
import { cleanURI } from "../utils/cleanURI";

export default function ContentCard(props) {

  const [color, setColor] = useState(null) // "light" / "dark"
  const [content, setContent] = useState(null) // JSON

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const search = useLocation().search

  useEffect(() => {
    setColor(props.color);
    setContent(props.content);
  }, [props])

  return (
    color && content && (
      <div
        className={`content-card content-card-${color}`}
      >
        <div
          className="card-image"
          style={{ backgroundImage: `url(${cleanURI(content.attributes.preview_pict) || cleanURI(content.attributes.cover_pict)})` }}
          onClick={() => {
            setModal(content, navigate, location, search);
          }}
        >
          <div className='shadow' />
          <HalfMoonImages
            images
            likes={content?.attributes?.photos_from_random_users_who_liked_this}
            number={content?.attributes?.like_count}
          />
          <CardActions isLiked={content.attributes.is_liked} type={content.type} id={content.id} slug={content.attributes?.slug} />
        </div>
        <div className="card-details">
          <div className="title-container">
            <p className="title">
              {content.attributes.title}
            </p>
            <p className="sub">
              {props.content.type === "video" ? content.attributes.subtitle : content.attributes.location}
            </p>
          </div>
          {buttons(props.content.type, content.attributes.formatted_date, false, content, setModal, navigate, location, search)}
        </div>
      </div>
    )
  )
}

function buttons(type, hours, isAdded, content, setModal, navigate, location, search) {
  if (type === 'video') {
    return <button className='button button-video'
      onClick={() => {
        setModal(content, navigate, location, search);
      }}
    >
      <p>Vedi ora</p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
      </svg>
    </button>
  }
  else if (type === "activity") {
    return (
      // <button
      //   className={'button button-activity button-added-' + isAdded}
      //   onClick={() => { redirectToCalendar(content) }}
      // >
      //   {isAdded ?
      //     <div className="added">
      //       <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      //         <path d="M1 6.5L5 10.5L11 1.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      //       </svg>
      //       <p>Aggiunto</p>
      //     </div>
      //     :
      //     <p>Aggiungi</p>
      //   }
      //   <div className='separator' />
      //   <p className='hours'>{hours}</p>
      // </button>
      <button
        className='button'
        onClick={() => {
          setModal(content, navigate, location, search);
        }}
      >
        <p>Scopri di più</p>
      </button>
    )
  }
}


