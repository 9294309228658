//PACKS
import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/api";

//COMPONENTS
import BlurredBackground from "../components/BlurredBackground";
import HomepageVerticalSlider from '../components/HomepageVerticalSlider'
import DefaultContentSection from "../components/DefaultContentSection";
import StatisticsGrid from '../components/StatisticsGrid'
import Footer from '../components/Footer'
import MobileCarousel from "../components/MobileCarousel"
import FeaturedCarousel from '../components/FeaturedCarousel';
import SeeMoreBanner from '../components/SeeMoreBanner'
//
import Responsive from '../utils/Responsive';
//
import useFetch from "../utils/useFetch";

export default function Video() {

  const lastVideos = useFetch("french/videos/last")
  const suggestedVideos = useFetch('french/videos/featured')
  const otherVideos = useFetch('french/videos/others')

  const backgroundImage = lastVideos?.data?.data[0].attributes.preview_pict

  return (
    suggestedVideos && otherVideos &&
    <div>
      <BlurredBackground image={backgroundImage} />
      <div style={{ height: '88px' }} />
      <div className="fit">
        <HomepageVerticalSlider videos={lastVideos} />
        <div className='sectionSpacer' />
      </div>
      <DefaultContentSection content={{ title: "Suggeriti per te", cards: suggestedVideos }} color={"light"} />
      {
        otherVideos &&
        <div className="container-w-titlepad container-w-titlepad-white">
        <p className="titlepad titlepad-light">
          Archivio
        </p>
        <Responsive>
          <div className="fit ">
            <MobileCarousel cards={otherVideos?.data?.data} color={"light"} />
          </div>
          <FeaturedCarousel content={otherVideos} />
        </Responsive>
      </div>
      }
      <div className="sectionSpacer sectionSpacer-white" />
      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  )
}