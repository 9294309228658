import { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import { createStore, action, StoreProvider } from "easy-peasy";
import WebFont from "webfontloader"; // Import the WebFont component
import axiosInstance from "./utils/api";

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Calendar from "./pages/Calendar";
import Video from "./pages/Video";
import Education from "./pages/Education";
import CityPage from "./pages/CityPage";
import Account from "./pages/Account";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Register from "./pages/Register";
import SingupModal from "./components/SingupModal";
import ContentModal from "./components/ContentModal";
import Autovalutazione from "./pages/Autovalutazione";
import ShareModal from "./components/ShareModal";
import MiniChat from "./components/MiniChat";
import AskMeAnything from "./pages/AskMeAnything";

function App() {

  const [fontsLoaded, setFontsLoaded] = useState(null);
  const [authenticated, setAuthenticated] = useState(null);

  const validateToken = async () => {
    try {
      const validateTokenResponse = await axiosInstance.get('/auth/validate_token', {
        headers: {
          "access-token": localStorage.getItem('accessToken'),
          "client": localStorage.getItem('client'),
          "uid": localStorage.getItem('uid')
        }
      });
      if (validateTokenResponse.status === 200) {
        const storePicturePromise = new Promise((resolve, reject) => {
          localStorage.setItem('userPicture', validateTokenResponse.data.data.data.attributes.profile_photo_url);
          resolve();
        });
        storePicturePromise
          .then((result) => {
          })
          .catch((error) => {
          });

        setAuthenticated(true)
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');
        localStorage.removeItem('userPicture');
        setAuthenticated(false)
      }
    } catch (error) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('client');
      localStorage.removeItem('uid');
      localStorage.removeItem('userPicture');
      setAuthenticated(false)
    }
  }

  useEffect(() => {
    validateToken()
  }, []);

  useEffect(() => {
    //
    WebFont.load({
      custom: {
        families: ["Satoshi"],
        urls: ["./index.css"],
      },
      active: () => {
        setFontsLoaded(true);
      },
    });
    //

  }, []);

  const store = createStore({
    poll: {},
    changePoll: action((state, payload) => {
      state.poll = payload;
    }),
    signupModal: false,
    setSignupModal: action((state, payload) => {
      state.signupModal = payload;
    }),
    shareModal: false,
    setShareModal: action((state, payload) => {
      state.shareModal = payload;
    })
  });

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  return (
    <div>
      {fontsLoaded && authenticated !== null && (
        <StoreProvider store={store}>
          <BrowserRouter>
            <Navbar ifNotLoggedIn={!authenticated} />
            <Routes>
              {/* normal routes */}
              <Route path='/' element={<Home />} />
              <Route path='/calendario' element={<Calendar />} />
              <Route path='/video' element={<Video />} />
              <Route path='/education' element={<Education />} />
              <Route path='/citta/:cityName' element={<CityPage />} />
              <Route path='/maturita-digitale' element={<Autovalutazione />} />
              <Route path='/ask-me-anything' element={<AskMeAnything />} />
              {/* login / register routes */}
              <Route path='/login' element={<Login setAuthenticated={setAuthenticated} />} />
              <Route path='/register' element={<Register setAuthenticated={setAuthenticated} />} />
              {/* protected routes */}
              <Route
                path="/account"
                element={
                  <ProtectedRoute user={authenticated}>
                    <Account />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account/settings"
                element={
                  <ProtectedRoute user={authenticated}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              {/* catch all route */}
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
            <SingupModal />
            <ShareModal />
            <ContentModal />
            <MiniChat />
          </BrowserRouter>
        </StoreProvider>
      )}
    </div>
  );
}

export default App;
