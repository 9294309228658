export function setModal(content, navigate, location, search) {


  if (search !== "") {
    console.log('I should first navigate to blank')
    navigate(location);
    setTimeout(() => {
      loadContent(content, navigate, location)
    }, 500)
  } else {
    loadContent(content, navigate, location)
  }
}

function loadContent(content, navigate, location) {
  let param = null

  console.log(content);

  switch (content.type) {
    case 'activity':
      param = "?" + content.type.toLowerCase() + "=" + content.id;
      break;
    case 'video':
      param = "?" + content.type.toLowerCase() + "=" + content.attributes.slug;
      break;
    case 'education':
      param = "?" + content.type.toLowerCase() + "=" + content.attributes.slug;
      break;
    default:
      param = '/'
  }

  navigate(location + param);
}