//PACKS
import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion"

//STYLES
import "../styles/calendarView.scss"

//COMPONENTS
import CalendarCard from './CalendarCards/CalendarCard'
import Responsive from '../utils/Responsive'

export default function CalendarView(props) {

  const [months, setMonths] = useState(null)
  const [openPosition, setOpenPosition] = useState(1)

  function handleOpenPosition(newPos) {
    setOpenPosition(newPos)
  }

  useEffect(() => {

    let tempMonths = []
    props.calendar.map((month) => {
      tempMonths.push({ ...month, count: 0 })
    })
    setMonths(tempMonths)
  }, [props])

  function handleMoreButton(monthId) {
    let tempMonths = []
    months.map((month, id) => {
      if (id === monthId) {
        month.count = month.count + 2
      }
      tempMonths.push(month)
    })
    setMonths(tempMonths)
  }

  return (
    <Responsive breakpoint={1280}>
      <Mobile months={months} handleMoreButton={handleMoreButton} openPosition={openPosition} handleOpenPosition={handleOpenPosition.bind(this)} />
      <Desktop months={months} handleMoreButton={handleMoreButton} />
    </Responsive>
  )
}

function Desktop({ months, handleMoreButton }) {
  return (
    <div className='fit'>
      <div className="calendar-view">
        {months && months.map((month, id) => (
          <div key={id}>
            {MonthView(month, id, handleMoreButton.bind(this))}
          </div>
        ))}
      </div>
    </div>)
}

function Mobile({ months, handleMoreButton, openPosition, handleOpenPosition }) {
  return (
    <div className='calendar-view calendar-view-mobile'>
      <motion.div
        className='container'
        animate={{ x: carouselAnimation(openPosition, window.innerWidth) }}
      >
        {months && months.map((month, index) => (
          <div
            key={index}
            onClick={() => { handleNext(index, openPosition, handleOpenPosition, window.innerWidth) }}
          >
            {MonthView(month, index, handleMoreButton.bind(this))}
          </div>
        ))}
      </motion.div>
    </div>
  )
}

function MonthView(month, monthId, handleMoreButton) {
  return (
    <div className="month">
      <div className="header">
        {month.month.length > 3 ?
          <p className="today">Oggi</p>
          :
          <>
            <p className='mth'>{month.month}</p>
            <p className='yr'>/{month.year}</p>
          </>
        }
      </div>
      <div className="activities">
        {
          month.activities.length > 0 ?
            month.activities.slice(month.count, month.count + 2).map((activity, id) => (
              <div
                key={activity.id}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                style={{ zIndex: 100 - id }}
              >
                <CalendarCard activity={activity} />
              </div>
            ))
            :
            <div className="no-events-card">
              <p>No events in {month.month}</p>
            </div>
        }
      </div>
      {
        month.activities.length > month.count + 2 &&
        <button className='more-button' onClick={() => { handleMoreButton(monthId) }}>
          <p>Altri eventi in {month.month}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M13 7L8 12L3 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      }
    </div>
  )
}

function carouselAnimation(openPosition, viewportWidth) {
  if (viewportWidth <= 480) {
    return `calc(var(--margin) + -3 * (var(--col) * ${openPosition - 1} + 20px * ${openPosition <= 1 ? 0 : openPosition - 1}))`
  }
  else {
    return `calc(var(--margin) + -4 * (var(--col) * ${openPosition - 1} + 20px * ${openPosition <= 1 ? 0 : openPosition - 1}))`
  }

}

function handleNext(index, openPosition, setOpenPosition, viewportWidth) {
  if (viewportWidth <= 480) {
    console.log(index, openPosition)
    if (index === openPosition) {
      console.log('ck')
      setOpenPosition(openPosition + 1)
    }
  } else if (viewportWidth > 481 && viewportWidth < 1280) {
    if (index === openPosition + 1) {
      setOpenPosition(openPosition + 1)
    }
  }
}

const content = [
  {
    month: "Ott",
    year: "23",
    activities: [
      {
        "id": 0,
        "title1": "Vox Happening",
        "title2": "On The Go 1",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      },
      {
        "id": 1,
        "title1": "Vox Happening",
        "title2": "On The Go 2",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      },
      {
        "id": 2,
        "title1": "Vox Happening",
        "title2": "On The Go 3",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      }
    ]
  },
  {
    is_today: true,
    activities: [
      {
        "id": 0,
        "title1": "Vox Happening",
        "title2": "On The Go 1",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      }
    ]
  },
  {
    month: "Nov",
    year: "23",
    activities: [
      {
        "id": 0,
        "title1": "Vox Happening",
        "title2": "On The Go 1",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      },
      {
        "id": 1,
        "title1": "Vox Happening",
        "title2": "On The Go 2",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      },
      {
        "id": 2,
        "title1": "Vox Happening",
        "title2": "On The Go 3",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      }
    ]
  },
  {
    month: "Dic",
    year: "23",
    activities: [
      {
        "id": 0,
        "title1": "Vox Happening",
        "title2": "On The Go 1",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      },
      {
        "id": 1,
        "title1": "Vox Happening",
        "title2": "On The Go 2",
        "organisation": "Apple Inc.",
        "location": "Modena, EX AEM",
        "hours": "12 Oct 08:30 - 12:00"
      }
    ]
  },
]
