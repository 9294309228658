import React, { memo, useState, useEffect } from "react";
import "../styles/coworkingCard.scss";

const address = {
  modena: 'Viale Buon Pastore, 43',
  ferrara: 'Via Castelnuovo, 10',
  forli: 'Via Caterina Sforza. 45',
  piacenza: 'Piazza Casali, 10'
}

const CoworkingCard = (props) => {

  return (
    <>
      <a
        rel="noreferrer"
        href={`https://laboratorioaperto${props.cityName === "forlì" ? "forli" : props.cityName}.it/gli-spazi/`} target="_blank"

      >
        <div
          className={`${props.cityPage ? "fit-size" : ""} coworkingCard-container`}
          style={{ backgroundImage: `url(${props.content})` }}
        >
          {/* <HalfMoonImages images /> */}

          <div className="content-group">
            <p className="title"> {props.cityName} </p>
            <p className="subtitle"> {address[props.cityName]} </p>
          </div>
          <div className="shadow" />
        </div>
      </a>
    </>
  );
};

export default memo(CoworkingCard);
