import React from "react";
import "../styles/statisticsGrid.scss";

import forli from "../assets/images/cover_forli.jpg";
import modena from "../assets/images/cover_modena.jpg";
import piacenza from "../assets/images/cover_piacenza.jpg";
import ferrara from "../assets/images/cover_ferrara.jpg";
import login from "../assets/images/login-bg.jpg";
import hero from "../assets/images/hero.jpg";
import hero2 from "../assets/images/hero2.jpg";
import form from "../assets/images/forms_3.jpg";

const arrOfBlocks = Array.from({ length: 8 }, (_, index) => index + 1);

const StatisticsGrid = () => {
  // const cityImages = {
  //   forlì: forli,
  //   modena: modena,
  //   piacenza: piacenza,
  //   ferrara: ferrara,
  //   // Add more cities here following the pattern
  // };

  const cityImages = [
    forli,
    modena,
    piacenza,
    ferrara,
    login,
    hero,
    hero2,
    form,
  ];

  return (
    <div className="statistics-grid-container">
      <div className="line-separator line-separator-top" />
      <div className="line-separator line-separator-bot" />
      <div className="left-side">
        <div className="first-row-content">
          <div className="logo">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.80962 7.96188L15 0.92376L27.1904 7.96188V22.0381L15 29.0762L2.80962 22.0381V7.96188Z"
                stroke="white"
                strokeWidth="1.6"
              />
              <path
                d="M14.9999 14.9999V29.7058M27.4999 22.2058L2.35284 7.79399M2.35284 22.2058L27.647 7.79399M21.6176 11.3234L9.11755 3.97046"
                stroke="white"
                strokeWidth="1.6"
              />
              <path
                d="M21.4706 11.3235V25.5882"
                stroke="white"
                strokeWidth="1.6"
              />
            </svg>
          </div>
          <div className="content">
            <p className="title">Laboratori Aperti </p>
            <p className="description">
              Nascono per dare concreta attuazione all’Agenda urbana europea e
              all’Agenda Digitale della Regione Emilia-Romagna
            </p>
          </div>
        </div>
        <div className="statistics-grid">
          <div className="row1">
            <div>
              <span>4 Hub</span>
              <p>gestiti</p>
            </div>
            <div>
              <span>3000+ U18</span>
              <p>formati</p>
            </div>
          </div>
          <div className="row2">
            <div>
              <span>100K+</span>
              <p>visitatori all'anno</p>
            </div>
            <div>
              <span>10+ anni</span>
              <p>in innovazione</p>
            </div>
          </div>
        </div>
      </div>

      <div className="right-side">
        <div className="shadow shadow-top" />
        <div className="shadow shadow-bot" />
        <div className="row1-grid">
          {cityImages.slice(0, 2).map((image, index) => (
            <div
              key={index}
              className={`row1 r${index + 1}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        <div className="row2-grid">
          {cityImages.slice(2, 6).map((image, index) => (
            <div
              key={index}
              className={`row1 r${index + 1}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        <div className="row3-grid">
          {cityImages.slice(6, 8).map((image, index) => (
            <div
              key={index}
              className={`row1 r${index + 1}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatisticsGrid;
