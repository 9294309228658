export const preventBodyScrolling = (overflow) => {
  switch (overflow) {
    case "hidden":
      document.body.style.overflow = 'hidden';
      break;
    case "unset":
      document.body.style.overflow = 'unset';
      break;
    default:
      document.body.style.overflow = 'unset';
      break;
  }
}