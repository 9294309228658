import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://164.92.141.172:8080/api/v1';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to include the access token in the request header
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    const client = localStorage.getItem('client');
    const uid = localStorage.getItem('uid');
    if (accessToken && client && uid) {
      config.headers['access-token'] = `${accessToken}`;
      config.headers.client = `${client}`;
      config.headers.uid = `${uid}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// TODO: Add a response interceptor to refresh the access token if it's expired

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       const refreshToken = localStorage.getItem('refreshToken');
//       const refreshedTokens = await axios.post(`${API_BASE_URL}/refresh-token`, {
//         refreshToken,
//       });

//       localStorage.setItem('accessToken', refreshedTokens.data.accessToken);
//       localStorage.setItem('refreshToken', refreshedTokens.data.refreshToken);

//       return axiosInstance(originalRequest);
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
