const authorization = 'Bearer 3840|ofzK0H1kqjth4gn4t2LBzdNmvurSzutVE9JwiBTG4398b547'
const projectId = '31838'

export function generateTimestampId() {
  // Get current timestamp in milliseconds
  const timestamp = new Date().getTime();

  // Generate a unique ID using the timestamp
  const id = `id_${timestamp}`;

  return id;
}

export function clearChat(setSessionId, setMessages, inputRef) {

  setSessionId(null);
  setMessages([]);
  inputRef.current.value = ""

}

export function createChat(setSessionId, input) {

  // console.log('creating chat...')

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: authorization
    },
    body: JSON.stringify({ name: input })
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`https://app.customgpt.ai/api/v1/projects/${projectId}/conversations`, options)
      const result = await response.json()
      setSessionId(result.data.session_id)
      // console.log('created chat with id', result.data.session_id);
      return result.data.session_id;
    }
    catch (err) {
      console.log(err)
    }
  }

  return fetchData()

}

export function fetchChat(sessionId, setMessages) {
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      authorization: authorization
    }
  };

  fetch(`https://app.customgpt.ai/api/v1/projects/${projectId}/conversations/${sessionId}/messages?page=1&order=desc`, options)
    .then(response => response.json())
    .then(response => {
      // console.log("aici --", response.data);
      setMessages(response.data.messages.data)
    }
    )
    .catch(err => console.error(err));
}

export async function askChat(saved_sessionId, setSessionId, input, inputRef, messages, setMessages, setThinking) {

  // cosmetic thinking 
  setThinking(true)
  if (inputRef !== null) {
    inputRef.current.value = ""
  }
  // ------------------------

  // setting question only to messages
  setMessages([...messages, { user_query: input, openai_response: "..." }])
  // --------------------------------------------

  // choosing between saved session or new session
  let sessionId = null
  if (saved_sessionId === null) {
    try {
      sessionId = await createChat(setSessionId, input)
      console.log(sessionId)
    }
    catch (err) {
      console.log(err)
    }
  } else {
    sessionId = saved_sessionId
  }
  //-----------------------------------------------------

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: authorization
    },
    body: JSON.stringify({ response_source: 'default', prompt: input })
  };

  fetch(`https://app.customgpt.ai/api/v1/projects/${projectId}/conversations/${sessionId}/messages?stream=false&lang=it`, options)
    .then(response => response.json())
    .then(response => {
      setMessages([...messages, response.data]);
      setThinking(false);
    })
    .catch(err => console.error(err));

}



export function getAllChats(setChats) {

  const options = {
    method: 'GET', headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: authorization
    }
  };

  fetch(`https://app.customgpt.ai/api/v1/projects/${projectId}/conversations?page=1&order=desc&orderBy=id&userFilter=all`, options)
    .then(response => response.json())
    .then(response => setChats(response.data.data))
    .catch(err => console.error(err));

}