export default function validateFields(e) {

  let body = {}

  let filledFields = []

  let errorFields = []

  const fields = [
    {
      name: "first_name",
      clean_name: "Il nome inserito non è valido",
      value: e.target?.first_name?.value,
      pattern: /^(?!\s+$)[a-zA-Z]+(['\-][a-zA-Z]+)?$/

    },
    {
      name: "last_name",
      clean_name: "Il cognome inserito non è valido",
      value: e.target?.last_name?.value,
      pattern: /^(?!\s+$)[a-zA-Z]+(['\-][a-zA-Z]+)?$/
    },
    {
      name: "email",
      clean_name: "Il email inserito non è valido",
      value: e.target?.email?.value,
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    },
    {
      name: "phone",
      clean_name: "Il telefono inserito non è valido",
      value: e.target?.phone?.value,
      pattern: /^\+(?:[0-9] ?){6,14}[0-9]$/
    },
    {
      name: "year_of_birth",
      clean_name: "Anno di nascita inserito non valido",
      value: e.target?.year_of_birth?.value,
      pattern: /^[1-9]\d{3,3}$/
    },
  ]

  fields.forEach((field, id) => {
    if (field.value && field.value !== "") {
      filledFields.push(field)
    }
  })

  filledFields.map((field) => {
    if (field?.pattern.test(field.value)) {
      body[field.name] = field.value
    } else {
      errorFields.push(field.clean_name)
    }
  })

  return { body: body, errorFields, valid: errorFields.length ? false : true }

}