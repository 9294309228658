import React, { useState, useEffect } from 'react'
import axiosInstance from "../utils/api";
//
import BlurredBackground from '../components/BlurredBackground'
import HomepageVerticalSlider from '../components/HomepageVerticalSlider'
import FeaturedCarousel from '../components/FeaturedCarousel';
import DefaultContentSection from "../components/DefaultContentSection";
import SeeMoreBanner from '../components/SeeMoreBanner'
import CitiesGrid from "../components/CitiesGrid"
import MobileCarousel from "../components/MobileCarousel"
import AutovalutazioneCard from "../components/AutovalutazioneCard"
//
import StatisticsGrid from '../components/StatisticsGrid'
import Footer from '../components/Footer'
import useFetch from '../utils/useFetch';
import Responsive from '../utils/Responsive';

export default function Home(props) {

  const lastVideos = useFetch('/french/videos/last')
  const featuredActivities = useFetch('/french/activities/featured')
  const suggestedVideos = useFetch('/french/videos/featured')
  const cities = useFetch('/french/organizers')

  const backgroundImage = lastVideos?.data?.data[0].attributes.preview_pict

  return (
    lastVideos && featuredActivities && suggestedVideos && cities &&
    <div>
      <BlurredBackground image={backgroundImage} />
      <div style={{ height: '88px' }} />
      {/* ------------------------------------------------------------------------------------------------ */}
      <div className="fit">
        <HomepageVerticalSlider videos={lastVideos} />
      </div>
      {/* ------------------------------------------------------------------------------------------------ */}
      <div className="container-w-titlepad">
        <p className="titlepad titlepad-color">
          Eventi curati dai Laboratori Aperti
        </p>
        <Responsive>
          <div className="fit ">
            <MobileCarousel cards={featuredActivities?.data?.data} color={"dark"} />
          </div>
          <FeaturedCarousel content={featuredActivities} />
        </Responsive>
        <div className="fit">
          <SeeMoreBanner calendario />
        </div>
      </div>
      {/* ------------------------------------------------------------------------------------------------ */}
      <DefaultContentSection content={{ title: "Suggeriti per te", cards: suggestedVideos }} type={"video"} color={"dark"} />
      <div className="fit">
        <SeeMoreBanner video />
      </div>
      {/* ------------------------------------------------------------------------------------------------ */}
      <div className="fit">
        <CitiesGrid cities={cities} />
      </div>
      <div className='sectionSpacer' />
      {/* ------------------------------------------------------------------------------------------------ */}
      <div className="fit" style={{ marginBottom: 20 }}>
        <SeeMoreBanner autovalutazione />
      </div>
      <div className="fit grid-2">
        {autovalutazioneCards.map((card, index) => (
          <div key={index}>
            <AutovalutazioneCard content={card} />
          </div>
        ))}
      </div>
      <div className='sectionSpacer' />
      {/* ------------------------------------------------------------------------------------------------ */}

      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  )
}

const autovalutazioneCards = [
  {
    "title": "Scopri i tuoi talenti",
    "description": "“Scopri i tuoi talenti” è uno strumento di self-assessment e orientamento progettato da MakerDojo che permette a studenti e studentesse di identificare le aree in cui in cui esiste, a livello individuale, il maggior potenziale di sviluppo dei punti di forza.",
    "photo": "forms_1",
    "link": "https://www.readytodigital.it/autovalutazione",
    "stats": [
      {
        "key": "categorie",
        "value": 6
      },
      {
        "key": "domande",
        "value": 88
      },
      {
        "key": "minuti",
        "value": 15
      },
    ]
  },
  {
    "title": "Digital Readiness",
    "description": "Un self assessment che permette di avere indicazioni in merito al grado di maturità digitale della tua azienda. Restituisce automaticamente al termine del questionario un report sintetico con il posizionamento dell’impresa rispetto ai processi produttivi",
    "photo": "forms_2",
    "link": "https://www.readytodigital.it/surveys/1",
    "stats": [
      {
        "key": "categorie",
        "value": 3
      },
      {
        "key": "domande",
        "value": 11
      },
      {
        "key": "minuti",
        "value": 5
      },
    ]
  },
  {
    "title": "Self Cultura X.0",
    "description": "Mappa la maturità digitale della tua istituzione culturale; posizonamento, strategia, produzione. Restituisce automaticamente al termine del questionario un report sintetico ed indicazione sul grado di trasformazione in attore di progettualità ed innovazione",
    "photo": "forms_3",
    "link": "https://www.readytodigital.it/surveys/3",
    "stats": [
      {
        "key": "categorie",
        "value": 4
      },
      {
        "key": "domande",
        "value": 11
      },
      {
        "key": "minuti",
        "value": 5
      },
    ]
  },
  {
    "title": "First Match Diritto Sul Informatico",
    "description": "Prenota un'ora di incontro o call gratuiti con un Consulente di Colin & Partners. Saremo felici di supportarti, rispetto alle tue esigenze sui temi del diritto delle nuove tecnologie, nella ricerca dell’approccio più efficace per il tuo business.",
    "photo": "forms_4",
    "link": "https://firstmatchdirittoinformatico.it/",
    "stats": [
      {
        "key": "categorie",
        "value": 1
      },
      {
        "key": "domande",
        "value": 2
      },
      {
        "key": "minuti",
        "value": 60
      },
    ]
  },
]
