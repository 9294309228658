//PACKS
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, useMotionValue } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

//STYLES
import "../styles/mobileCarousel.scss"

//COMPONENTS
import ContentCard from "./ContentCard";

export default function MobileCarousel(props) {

  const [cards, setCards] = useState(null)

  useEffect(() => {
    setCards(props.cards)
  }, [props])


  const [isVisible, setIsVisible] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };
  const paginate = (newDirection) => {
    setIsVisible((isVisible + newDirection) % props.cards.length);
    const newIndex = (currentCardIndex + newDirection) % props.cards.length;
    setCurrentCardIndex(newIndex < 0 ? props.cards.length - 1 : newIndex);

  };

  // const bullets = props.cards.map((_, index) => (
  //   <div
  //     key={index}
  //     className={`bullet ${index === currentCardIndex ? "active" : ""}`}
  //     onClick={() => setCurrentCardIndex(index)}
  //   ></div>
  // ));

  const variants = {
    enter: {
      x: 600,
      opacity: 0.6,
      transition: {
        duration: 0.4,
      },
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      zIndex: 0,
      x: -600,
      opacity: 0.6,
      transition: {
        duration: 0.4,
      },
    },
  };
  const filledVariants = {
    visible: (isVisible) => ({
      width: `calc((100% / ${props.cards.length}) *${isVisible + 1})`,
    }),
  };

  function preventDefault(e) {
    if (e.cancelable) e.preventDefault();
  }

  function disableScroll() {
    document.body.addEventListener("touchmove", preventDefault, {
      passive: false,
    });
  }
  function enableScroll() {
    document.body.removeEventListener("touchmove", preventDefault);
  }

  return cards &&
    <div className="carousel">
      <AnimatePresence>
        <motion.div
          className="carouselContent"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            }

            preventDefault(e);
            enableScroll();
          }}
          key={uuidv4()}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          style={{ position: "absolute" }}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 1.5 },
          }}
          onDragStart={(e) => {
            preventDefault(e);
            disableScroll();
          }}
        >
          {/* <ActivityCard card={props.cards[isVisible]} index={0} />  */}
          {/* {props?.cards[isVisible]} */}
          <ContentCard content={props?.cards[isVisible]} index={0} color={props?.color} />
        </motion.div>
      </AnimatePresence>
      {/* <div style={{ height: "20px", width: "100%" }} /> */}
      <div className="carouselBullets">
        {
          cards && cards.map((_, index) => (
            <div
              key={index}
              className={`bullet ${index === currentCardIndex ? "active" : ""}`}
              onClick={() => setCurrentCardIndex(index)}
            ></div>
          ))
        }

      </div>
    </div>
}
