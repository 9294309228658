//PACKS
import React, { useState, useEffect } from 'react'

//STYLES
import "../styles/favoriteContentSection.scss"

//COMPONENTS
import Responsive from '../utils/Responsive'
import ContentCard from './ContentCard'
import MobileCarousel from './MobileCarousel'

export default function FavoriteContentSection(props) {

  const [content, setContent] = useState(null)
  const [color, setColor] = useState(null)

  useEffect(() => {

    setColor(props.color)
    setContent(props.content)

    console.log('cards', props.content.cards)

  }, [props])


  //
  const [filterToggle, setFilterToggle] = useState(false)
  const [filter, setFilter] = useState(null)

  useEffect(() => {
    if (filter === null) {
      return
    } else {
      //make an axios call to get the desired content and set it to state
    }
  }, [filter])

  function displayFilterButton(color) {
    if (!filterToggle) {
      return (
        <button
          className={`filter-button titlepad titlepad-${color}`}
          onClick={() => { setFilterToggle(true) }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
          </svg>
          <p>Filtra il contenuto</p>
        </button>
      )
    }
    else {
      return (
        <div className={`filter-container titlepad titlepad-${color}`}>
          <button className="close-modal" onClick={() => { setFilterToggle(false) }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <button class='filter-option' onClick={() => { setFilter("activity"); setFilterToggle(false) }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
            <p>Eventi</p>
          </button>
          <button class='filter-option' onClick={() => { setFilter("video"); setFilterToggle(false) }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
              <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>
            <p>Video</p>
          </button>
          <button class='filter-option' onClick={() => { setFilter(false); setFilterToggle(false) }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
            </svg>
            <p>Tutti</p>
          </button>
        </div>
      )
    }
  }

  return (
    content && color &&
    <div>
      <Responsive>
        <Mobile color={color} content={content} displayFilterButton={displayFilterButton} />
        <Desktop color={color} content={content} displayFilterButton={displayFilterButton} />
      </Responsive>
    </div>)
}


function Mobile({ color, content, displayFilterButton }) {
  return (
    <div className="container-w-titlepad" style={{ backgroundColor: color === "light" ? "white" : "black" }}>
      {displayFilterButton(color)}
      <div className="fit ">
        <MobileCarousel cards={content.cards} color={color} type={"favorite"} />
      </div>
    </div>
  )
}

function Desktop({ color, content, displayFilterButton }) {
  return (
    <div className="container-w-titlepad" style={{ backgroundColor: color === "light" ? "white" : "black" }}>
      {displayFilterButton(color)}
      <div className="fit grid" style={{ gap: "20px" }}>
        {content.cards.map((card, id) => (
          <div key={id}>
            <ContentCard content={card} color={color} type={card.type} />
          </div>
        ))}
      </div>
    </div>
  )
}