import React, { useState, useEffect } from "react";
import "../styles/citiesGrid.scss";
import CitiesGridComponent from "./CitiesGridComponent";

const CitiesGrid = (props) => {

  const cities = props.cities?.data?.data
  const [activeCity, setActiveCity] = useState(0);

  const handleClick = (index) => {
    setActiveCity(index);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    cities &&
    <div className="cities-grid-container">
      <div className="city-buttons-container">
        <div className="city-buttons">
          {cities?.map((elem, index) => (
            <a onClick={() => handleClick(index)} key={elem?.attributes.name} className={activeCity === index ? "city-button city-button-active" : "city-button"}>
              <div>
                <p>{capitalizeFirstLetter(elem?.attributes.slug)}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
      {activeCity !== null && activeCity !== undefined && (
        <CitiesGridComponent key={activeCity} keyIndex={activeCity} city={cities[activeCity]?.attributes} cities={cities} cityName={cities[activeCity]?.attributes.name.toLowerCase()} />
      )}
    </div>
  );
};

export default CitiesGrid;
