import React, { useState, useEffect } from 'react'

export default function Responsive(props) {

  const [width, setWidth] = useState(window.innerWidth)
  const [breakpoint, setBreakpoint] = useState(768)

  useEffect(() => {

    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])

  useEffect(() => {
    if (props.breakpoint) {
      setBreakpoint(props.breakpoint)
    }
  }, [
    props.breakpoint
  ])

  return (
    <>
      {
        width >= breakpoint ?
          props.children[1]
          :
          props.children[0]
      }
    </>
  )
}
