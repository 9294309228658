//PACKS
import React, { useState, useEffect } from 'react'
import axiosInstance from "../utils/api";

import useFetch from '../utils/useFetch';

//COMPONENTS
import BlurredBackground from '../components/BlurredBackground';
import HomepageVerticalSlider from '../components/HomepageVerticalSlider'
import CalendarView from '../components/CalendarView'
import DefaultContentSection from "../components/DefaultContentSection";
import ContentCard from '../components/ContentCard'
import MobileCarousel from '../components/MobileCarousel'
import StatisticsGrid from '../components/StatisticsGrid'
import Footer from '../components/Footer'
import { cleanURI } from "../utils/cleanURI";


export default function Calendar() {

  const calendarEventsResponse = useFetch('/french/calendars')
  console.log('here', calendarEventsResponse)
  let calendarEvents = []

  if (calendarEventsResponse?.data !== null) {

    for (const [key, value] of Object.entries(calendarEventsResponse?.data)) {
      if (value) {
        calendarEvents = [...calendarEvents, { month: value?.meta.title_short, year: value?.meta.year, activities: value?.data }]
      }
    }

    if (calendarEvents.length > 4) {
      calendarEvents = calendarEvents.slice(0, 4)
    }


    console.log('here too', calendarEvents)
  }

  const lastEvents = useFetch("/french/calendars/last");
  const events = useFetch("/french/calendars/featured");

  const backgroundImage = cleanURI(events?.data?.data[0].attributes.cover_pict)


  // const thumbnailBlur = events?.[0]?.thumbnail || ''
  return (
    // events && lastEvents && calendarEvents &&
    <div style={{ overflow: 'hidden' }}>
      <BlurredBackground image={backgroundImage} />
      <div style={{ height: '88px' }} />
      <div className="fit">
        <HomepageVerticalSlider videos={events} />
      </div>
      {
        calendarEvents.length > 0 && <CalendarView calendar={calendarEvents} />
      }
      <DefaultContentSection content={{ title: "Suggeriti per te", cards: events }} color={"light"} />
      <DefaultContentSection content={{ title: "Ultimo aggiunto", cards: lastEvents }} color={"light"} />
      <div className="sectionSpacer sectionSpacer-white" />
      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  )
}