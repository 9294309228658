//PACKS
import React from "react";

//STYLES
import "../styles/autovalutazioneCard.scss";

import forms_1 from "../assets/images/forms_1.jpg"
import forms_2 from "../assets/images/forms_2.jpg"
import forms_3 from "../assets/images/forms_3.jpg"
import forms_4 from "../assets/images/forms_4.jpg"

const images = {
  forms_1,
  forms_2,
  forms_3,
  forms_4 
}

const AutovalutazioneCard = (props) => {

  return (
    <div
      className="autovalutazioneCard-container"
    >
      <div className="images-row">
        <div className="images">
          <div
            className="square-image"
            style={{
              backgroundImage: `url(${images[props.content.photo]})`,
            }}
          >
            {" "}
          </div>
          {/* <div
            className="rounded-avatar"
            style={{
              backgroundImage: `url("https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80")`,
            }}
          >
            {" "}
          </div> */}
        </div>
        <div className="title-group">
          <p className="title"> {props.content.title} </p>
          <p className="subtitle"> Open Lab Digital </p>
        </div>
      </div>
      {/* <div className="title-row">
      

      </div> */}
      <div className="description-row">
        <p>
          {props.content.description}
        </p>
      </div>
      <div className="statistics-row">
        <div className="statistics">

          {props.content.stats.map((stat, index) => (
            <div className="stat" key={index}>
              <div className="how-many"> {stat.value} </div>
              <span> {stat.key} </span>
            </div>
          ))}
        </div>
        <div className="form-button">
          <a href={props.content.link} target="_blank">
            <div className="icon">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6465 3.86544L13.9117 2.59944C14.1755 2.33568 14.5332 2.1875 14.9062 2.1875C15.2793 2.1875 15.637 2.33568 15.9008 2.59944C16.1645 2.86319 16.3127 3.22093 16.3127 3.59394C16.3127 3.96695 16.1645 4.32468 15.9008 4.58844L7.9365 12.5527C7.53999 12.949 7.05102 13.2402 6.51375 13.4002L4.5 14.0002L5.1 11.9864C5.25996 11.4492 5.55123 10.9602 5.9475 10.5637L12.6465 3.86544ZM12.6465 3.86544L14.625 5.84394M13.5 11.0002V14.5627C13.5 15.0102 13.3222 15.4395 13.0057 15.7559C12.6893 16.0724 12.2601 16.2502 11.8125 16.2502H3.9375C3.48995 16.2502 3.06072 16.0724 2.74426 15.7559C2.42779 15.4395 2.25 15.0102 2.25 14.5627V6.68769C2.25 6.24013 2.42779 5.81091 2.74426 5.49444C3.06072 5.17798 3.48995 5.00019 3.9375 5.00019H7.5"
                  stroke="white"
                  strokeOpacity="0.8"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AutovalutazioneCard;
