//PACKS
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { preventBodyScrolling } from "../utils/preventBodyScrolling";

//COMPONENTS
import CitiesDropdown from "./CitiesDropdown";
import SearchBar from "./SearchBar";

//ASSETS
import { hamButtons, navbarButtons, cities } from "../assets/jsons/hamButtons";

const LinksAndSearchBar = ({ handleCitaButtonClick, citaModal, menuOpen }) => {

  const [clicked, setClicked] = useState(false);

  function handleSearchClick(value) {
    setClicked(value);
  }

  const [nextLinks, setNextLinks] = useState([]);

  const location = useLocation();

  // ---------- NAVBAR LINKS LOGIC ----------
  useEffect(() => {
    // Check if the current page's slug exists in hamButtons
    const isHamButton = hamButtons.some(
      (button) => button.slug === location.pathname
    );
    const isCities = cities.some((city) => city.slug === location.pathname);

    if (isHamButton || isCities) {
      // If it's a hamButton, set nextLinks to the entire navbarButtons array
      setNextLinks([...navbarButtons]);
    } else {
      // Find the current page's index in navbarButtons
      const currentPageIndex = navbarButtons.findIndex(
        (button) => button.slug === location.pathname
      );

      if (currentPageIndex !== -1) {
        // Calculate the indices of the next two links
        const nextIndex1 = (currentPageIndex + 1) % navbarButtons.length;
        const nextIndex2 = (currentPageIndex + 2) % navbarButtons.length;
        const nextIndex3 = (currentPageIndex + 3) % navbarButtons.length;
        const nextIndex4 = (currentPageIndex + 4) % navbarButtons.length;

        // Set the next two links in state
        setNextLinks(
          [
            navbarButtons[nextIndex1],
            navbarButtons[nextIndex2],
            navbarButtons[nextIndex3],
            navbarButtons[nextIndex4],
          ].sort((a, b) => a.index - b.index)
        );
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (clicked || menuOpen || citaModal) {
      preventBodyScrolling("hidden");
    } else {
      preventBodyScrolling("unset");
    }
  }, [clicked, menuOpen, citaModal]);

  return (
    <>
      <AnimatePresence>
        {/* -------------------- LINKS ↓ -------------------- */}
        <motion.div
          key="links"
          className="links"
          initial={{ opacity: 1, width: "fit-content" }}
          animate={
            !clicked
              ? { opacity: 1, width: "fit-content" }
              : { opacity: 0, width: "0px" }
          }
          exit={
            !clicked
              ? { opacity: 0, width: "0px" }
              : { opacity: 1, width: "fit-content" }
          }
          transition={{ duration: 0.4 }}
        >
          <div className="icon-links">
            {nextLinks.map((elem, index) => (
              <div
                key={index}
                className={
                  index === navbarButtons.length - 1
                    ? "mr-0 link-container"
                    : "link-container"
                }
              >
                <a
                  href={elem.index === 3 ? "" : elem.slug}
                  className="icon-and-text"
                  onClick={(event) => handleCitaButtonClick(event, elem)}
                >
                  {elem.icon}
                  <div className="">{elem.title}</div>
                </a>
                {index !== nextLinks.length - 1 && (
                  <div className="vertical-line-links"> </div>
                )}
              </div>
            ))}
          </div>
          <div className="vertical-line"> </div>

          {/* le citta modal --- absolute position  */}
          <CitiesDropdown cities={cities} citaModal={citaModal} />
        </motion.div>

        <motion.div
          key="search-bar"
          className="search-bar-half"
          // style={{ marginLeft: clicked ? "0" : "" }}
          initial={{
            width: "100%",
            transition: { delay: 0.1, duration: 1 },
          }}
        >
          <SearchBar handleSearchClick={handleSearchClick.bind(this)} clicked={clicked} />
        </motion.div>

        {/* IF ONE OF THE STATES BELOW IS TRUE IT WILL BE ACTIVATED THE OVERLAY (BLUR ALL THE PAGE) */}
        {(clicked || menuOpen || citaModal) && <div className="overlay"></div>}
      </AnimatePresence>
    </>
  );
};

export default LinksAndSearchBar;


