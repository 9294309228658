import axiosInstance from "../utils/api";

export default async function onInputChange(event, pictureRef, backgroundRef) {
  const local_file = URL.createObjectURL(event.target.files[0])
  const file = event.target.files[0]
  changeProfilePicture(file, local_file, pictureRef, backgroundRef)
}

async function changeProfilePicture(file, local_file, pictureRef, backgroundRef) {

  const body = new FormData()
  body.append('profile_photo', file)

  const headers = {
    "client": localStorage.getItem('client'),
    "access-token": localStorage.getItem('access-token'),
    "uid": localStorage.getItem('uid'),
  }

  pictureRef.current.src = local_file
  backgroundRef.current.style.backgroundImage = `url(${local_file})`

  const storePicturePromise = new Promise((resolve, reject) => {
    localStorage.setItem('userPicture', local_file);
    resolve();
  });
  storePicturePromise
    .then((result) => {
      console.log('storePicturePromise resolved with result ', result)
    })
    .catch((error) => {
      console.log(error)
    });

  try {
    await axiosInstance.patch('/french/users', body, { headers })
  }
  catch (err) {
    console.log(err)
  }

}