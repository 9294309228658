//PACKS
import { Fragment, useEffect, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

//UTILS
import useFetch from "../utils/useFetch"
import { handleGeneralForm, handlePasswordChange, handleDelete } from "../utils/useSettings"

//COMPONENTS
import BadInputAlert from '../components/BadInputAlert'


export default function Settings() {

  const userDataResponse = useFetch('/french/users/me')
  const userData = userDataResponse?.data?.data?.attributes

  const [error, setError] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)

  return (
    userData &&
    <>
      <DeleteAlert open={openDelete} setOpen={setOpenDelete} />
      <BadInputAlert error={error} />
      <div style={{ padding: "80px 0" }}>
        <div className="xl" style={{ margin: "0 var(--margin)" }}>
          <main>
            <div className="divide-y divide-white/5">
              <General userData={userData} setError={setError} />
              <Password userData={userData} setError={setError} />
              {/* <Delete userData={userData} setOpenDelete={setOpenDelete} /> */}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

function General({ userData, setError }) {
  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-white">Informazioni personali</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Utilizza un indirizzo permanente dove puoi ricevere la posta.
        </p>
      </div>
      <form className="md:col-span-2" onSubmit={(e) => { handleGeneralForm(e, setError) }}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full flex items-center gap-x-8">
            <img
              src={userData.profile_photo_url}
              alt=""
              className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
            />
            <div>
              <button
                type="button"
                className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
              >
                Cambia avatar
              </button>
              <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF o PNG. Massimo 1MB.</p>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
              Nome
            </label>
            <div className="mt-2">
              <input
                placeholder={userData.first_name}
                type="text"
                name="first_name"
                id="first-name"
                autoComplete="given-name"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-white">
              Cognome
            </label>
            <div className="mt-2">
              <input
                placeholder={userData.last_name}
                type="text"
                name="last_name"
                id="last-name"
                autoComplete="family-name"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-full">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                placeholder={userData.email}
                name="email"
                type="email"
                autoComplete="email"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
              Telefono
            </label>
            <div className="mt-2">
              <input
                id="phone"
                placeholder={userData.phone || 'e.g. +39 02 1234567'}
                name="phone"
                type="phone"
                autoComplete="phone"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
              Anno di nascita
            </label>
            <div className="mt-2">
              <input
                id="year"
                placeholder={userData.year_of_birth || 'e.g. 1975'}
                name="year_of_birth"
                type="number" min="1900" max="2099" step="1"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Salva impostazioni dell'account
          </button>
        </div>
      </form>
    </div>
  )
}

function Password({ userData, setError }) {
  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-white">Cambia password</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Aggiorna la tua password associata al tuo account.
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={(e) => { handlePasswordChange(e, setError) }}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-white">
              Password attuale
            </label>
            <div className="mt-2">
              <input
                id="current-password"
                name="current_password"
                type="password"
                autoComplete="current-password"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-full">
            <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-white">
              Nuova password
            </label>
            <div className="mt-2">
              <input
                id="new-password"
                name="new_password"
                type="password"
                autoComplete="new-password"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-full">
            <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-white">
              Conferma password
            </label>
            <div className="mt-2">
              <input
                id="confirm-password"
                name="confirm_password"
                type="password"
                autoComplete="new-password"
                className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Salva la nuova password
          </button>
        </div>
      </form>
    </div>
  )
}

function Delete({ userData, setOpenDelete }) {
  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-white">Elimina il mio account</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Non desideri più utilizzare il nostro servizio? Puoi eliminare il tuo account qui. Questa azione non è reversibile. Tutte le informazioni relative a questo account verranno eliminate permanentemente.
        </p>
      </div>

      <div className="flex items-start md:col-span-2">
        <button
          onClick={(e) => { e.preventDefault(); setOpenDelete(true) }}
          className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
        >
          Elimina il mio account
        </button>
      </div>
    </div>
  )
}

function DeleteAlert({ open, setOpen }) {

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Elimina account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Sicuro di voler disattivare il tuo account? Tutti i tuoi dati saranno rimossi permanentemente dai nostri server per sempre. Questa azione non può essere annullata.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                    onClick={(e) => handleDelete(e)}
                  >
                    Si, elimina
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancella
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
