//PACKS
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

//STYLES
import "../../styles/calendarCard.scss";

//COMPONENTS
import HalfMoonImages from "../HalfMoonImages";
import CardActions from "../CardActions";

//UTILS
import { redirectToCalendar } from "../../utils/redirectToCalendar.js"
import { setModal } from "../../utils/setModal.js";

const CalendarCard = ({ activity, ...props }) => {

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const search = useLocation().search

  return (
    activity &&
    <>
      <div className={
        props.location ?
          `calendarCard-container calendarCard-container-${props.location}`
          :
          "calendarCard-container"}
      >
        <div className="first-row">
          <div className="actions">
            <HalfMoonImages
              images
              likes={activity?.attributes?.photos_from_random_users_who_liked_this}
              number={activity?.attributes?.like_count}
            />
            <CardActions isLiked={activity.attributes.is_liked} type={activity.type} id={activity.id} />
          </div>
          <div className="title">
            <span>{activity.attributes.title}</span>
            {/* <p>{activity.description}</p> */}
          </div>
        </div>

        {!props.startAnim && (
          <AnimatePresence>
            <motion.div className="second-row">
              <div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 14H13.5M3 2H13M3.5 2V14M12.5 2V14M6 4.5H7M6 6.5H7M6 8.5H7M9 4.5H10M9 6.5H10M9 8.5H10M6 14V11.75C6 11.336 6.336 11 6.75 11H9.25C9.664 11 10 11.336 10 11.75V14"
                    stroke="#404A59"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="organization">{activity.attributes.categs[0]}  </span>
              </div>
              <div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7Z"
                    stroke="#4F5C71"
                    strokeOpacity="0.5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7Z"
                    stroke="#4F5C71"
                    strokeOpacity="0.5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="location">{activity.attributes.location}</span>
              </div>
            </motion.div>

            {/* <motion.div className="third-row" onClick={() => { redirectToCalendar(activity) }}>
              <div className="hour">
                <span> Add to calendar </span>
                <span className="time">

                  {activity.attributes.formatted_date}

                </span>
              </div>
              <div className="button">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>
              </div>
            </motion.div> */}
            <button className="show-more" onClick={() => {
              setModal({ ...activity, type: "activity" }, navigate, location, search);
            }}>
              <p>Scopri di più</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
            </button>
          </AnimatePresence>
        )}
      </div>
    </>
  );
};

export default CalendarCard;
