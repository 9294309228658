//PACKS
import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { flushSync } from "react-dom";

//COMPONENTS
import StackedCard from "./StackedCard";

//STYLES
import "../styles/cityPageVerticalCarousel.scss";
import CoworkingCard from "./CoworkingCard";

const TWEEN_FACTOR = 0.4;

const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max);

//SLIDER PROPS
const options = { axis: "y" };
const SLIDE_COUNT = 5;
// const slides = Array.from(Array(SLIDE_COUNT).keys());

const HomepageVerticalSlider = (props) => {
  const [cards, setCards] = useState(null);

  useEffect(() => {
    setCards(props.content);
  }, [props]);

  // const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [tweenValues, setTweenValues] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    if (!cards) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
      return numberWithinRange(tweenValue, 0, 1);
    });

    setTweenValues(styles);

    const current = Math.round(scrollProgress * (cards.length - 1));
    setCurrentSlide(current);
  }, [emblaApi, setTweenValues, setCurrentSlide, cards]);

  useEffect(() => {
    if (!emblaApi) return;

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);

    //   Auto Swipe Interval
    // const interval = setInterval(() => {
    //   const hasNextSlide = currentSlide < slides.length - 1;
    //   if (hasNextSlide) {
    //     emblaApi && emblaApi.scrollNext();
    //   } else {
    //     emblaApi && emblaApi.scrollTo(0);
    //   }
    // }, AUTO_SWIPE_INTERVAL);

    // return () => clearInterval(interval);
  }, [emblaApi, onScroll, currentSlide]);

  return (
    <>
      {cards && (
        <div className="city-page-vertical-carousel">
          <div
            className="embla__bullets"
            style={{ left: props.homePage ? "calc(100% + 20px)" : "-20px" }}
          >
            {cards.map((_, index) => (
              <div
                key={index}
                className={`embla__bullet ${
                  index !== currentSlide ? "active" : ""
                }`}
                onClick={() => emblaApi.scrollTo(index)}
              />
            ))}
          </div>

          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {cards &&
                cards[props.cityIndex]?.attributes?.coworking_picts?.map(
                  (card, index) => (
                    <div className="embla__slide" key={index}>
                      <div
                        className="embla__scale"
                        style={{
                          ...(tweenValues.length && {
                            transform: `scale(${tweenValues[index]})`,
                          }),
                        }}
                      >
                        <CoworkingCard
                          cityPage
                          content={card}
                          cityName={cards[props.cityIndex].attributes.slug}
                        />
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomepageVerticalSlider;
