//PACKS
import React, { useState, useEffect } from 'react';

//UTILS
import login from '../utils/useLogin';

//COMPONENTS
import BadInputAlert from '../components/BadInputAlert';

export default function Login() {

  const [error, setError] = useState(null)

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
  }, [])

  return (
    <>
      <BadInputAlert error={error} />
      <div className="h-screen v-max bg-slate-100">
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <LoginHeader />
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <LoginForm setError={setError} />
            <p className="mt-10 text-center text-sm text-gray-500">
              Non sei ancora un membro?{' '}
              <a href="/register" className="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Registrati ora!
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

function LoginHeader() {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <svg
        width="32"
        height="34"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className='mx-auto invert opacity-4'
      >
        <path
          d="M1.92276 5.62528L9.5 1.25056L17.0772 5.62528V14.3747L9.5 18.7494L1.92276 14.3747V5.62528Z"
          stroke="white"
          strokeWidth="1.3"
        />
        <path
          d="M9.50346 10.0002V19.3139M17.4201 14.5639L1.49365 5.43646M1.49365 14.5639L17.5133 5.43646M13.6946 7.67176L5.77797 3.01489"
          stroke="white"
          strokeWidth="1.3"
        />
        <path
          d="M13.6021 7.67163V16.7059"
          stroke="white"
          strokeWidth="1.3"
        />
      </svg>

      <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Accedi al tuo account
      </h2>
    </div>
  )
}

function LoginForm({ setError }) {
  return (
    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
      <form className="space-y-6" onSubmit={async (e) => { login(e, setError) }}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="username"
              type="email"
              autoComplete="email"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
            Password
          </label>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember_me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
              Ricordami
            </label>
          </div>

          <div className="text-sm leading-6">
            <a href="#" className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500">
              Password dimenticata?
            </a>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign in
          </button>
        </div>
      </form>

      <div>
        <div className="relative mt-10">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-white px-6 text-gray-900">Oppure continua senza un account</span>
          </div>
        </div>

        <div className="mt-6 ">
          <a
            href="/"
            className="cursor-pointer flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Continua non registrato
          </a>
        </div>
      </div>
    </div>
  )
}