//PACKS
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

//STYLES
import "../styles/citiesDropdown.scss";

const CitiesDropdown = ({ displayNoneForDesktop, citaModal, cities }) => {


// Function to compare two city names for sorting
const compareCityNames = (a, b) => {
  const nameA = a.cityName.toUpperCase(); // ignore case
  const nameB = b.cityName.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

  return (
    <div>
      <AnimatePresence>
        {citaModal && (
          <motion.div
         
            className={`cities-menu ${displayNoneForDesktop ? "display-none-cities-modal" : ""}`}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            <div className="rest-of-pages">
              {cities.sort(compareCityNames).map((button, index) => (
                <a href={button.slug} key={index}>
                  <span> {button.cityName} </span>
                  {button.icon}
                </a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CitiesDropdown;
