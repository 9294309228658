//PACKS
import React, { useState, useEffect } from 'react'
import { useStoreActions } from 'easy-peasy';


//STYLES
import "../styles/cardActions.scss"

import axiosInstance from "../utils/api";

export default function CardActions({ id, type, isLiked, slug }) {

  const [like, setLike] = useState(null)

  useEffect(() => {
    setLike(isLiked)
  }, [isLiked])

  return (
    <div className="card-actions">
      <LikeButton id={id} type={type} like={like} setLike={setLike.bind(this)} />
      <div className='separator' />
      <ShareButton id={id} type={type} slug={slug} />
    </div>
  )
}

function LikeButton({ id, type, like, setLike }) {

  const setSignupModal = useStoreActions((actions) => actions.setSignupModal);

  const submitLike = async (id, type, like, setLike) => {
    const data = {
      "id": id,
      "type": type
    }
    try {
      const submitLike = like ? await axiosInstance.delete(`/french/likes`, { data }) : await axiosInstance.post(`/french/likes`, data);
      if (submitLike.status === 200) {
        setLike(!like)
      }
      else if (submitLike.status === 401) {
        setSignupModal(true)

      }
    }
    catch (error) {
      console.log(error)
      setSignupModal(true)
    }
  }

  return (
    <button className='card-action-button' onClick={(event) => { event.stopPropagation(); submitLike(id, type, like, setLike) }}>
      {
        like ?
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" className="liked-heart">
            <path d="M7.99491 14.1804L7.98946 14.1781L7.97235 14.1687C7.87224 14.1137 7.77293 14.0571 7.67446 13.9992C6.4905 13.2957 5.38406 12.4692 4.37348 11.5336C2.58377 9.86363 0.6875 7.38557 0.6875 4.33349C0.6875 2.0561 2.60399 0.250055 4.91716 0.250055C5.56021 0.246906 6.19569 0.388768 6.77635 0.665092C7.35701 0.941416 7.8679 1.34509 8.27103 1.8461C8.67424 1.34499 9.18525 0.941251 9.76606 0.664923C10.3469 0.388595 10.9825 0.246788 11.6257 0.250055C13.9381 0.250055 15.8546 2.0561 15.8546 4.33349C15.8546 7.38635 13.9583 9.86441 12.1686 11.5328C11.158 12.4685 10.0516 13.2949 8.8676 13.9984C8.76913 14.0566 8.66982 14.1134 8.5697 14.1687L8.55259 14.1781L8.54714 14.1812L8.54481 14.182C8.46047 14.2266 8.36647 14.25 8.27103 14.25C8.17558 14.25 8.08159 14.2266 7.99724 14.182L7.99491 14.1804Z" fill="#FF005C" />
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14.75 5.1875C14.75 3.32375 13.1758 1.8125 11.234 1.8125C9.78275 1.8125 8.53625 2.657 8 3.86225C7.46375 2.657 6.21725 1.8125 4.76525 1.8125C2.825 1.8125 1.25 3.32375 1.25 5.1875C1.25 10.6025 8 14.1875 8 14.1875C8 14.1875 14.75 10.6025 14.75 5.1875Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
      }
    </button>)
}

function ShareButton({ id, type, slug }) {

  const setShareModal = useStoreActions((actions) => actions.setShareModal);

  function handleShareModal(event) {
    event.stopPropagation();
    let path = 'https://link.openlabdigital.com/'
    let search = null
    switch (type) {
      case 'activity':
        search = 'calendario?activity=' + id
        break;
      case 'video':
        search = "video?video=" + slug
        break;
      case 'education':
        search = "education?video=" + slug
        break;
      default:
        search = '/'
    }
    let link = path + search
    setShareModal(link)
  }

  return (
    <button onClick={(event) => { handleShareModal(event) }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
        <path d="M4.41276 8.18022C4.23116 7.85345 3.94619 7.59624 3.60259 7.44895C3.25898 7.30167 2.8762 7.27265 2.51432 7.36645C2.15244 7.46025 1.83196 7.67157 1.60317 7.96722C1.37438 8.26287 1.25024 8.62613 1.25024 8.99997C1.25024 9.37381 1.37438 9.73706 1.60317 10.0327C1.83196 10.3284 2.15244 10.5397 2.51432 10.6335C2.8762 10.7273 3.25898 10.6983 3.60259 10.551C3.94619 10.4037 4.23116 10.1465 4.41276 9.81972M4.41276 8.18022C4.54776 8.42322 4.62501 8.70222 4.62501 8.99997C4.62501 9.29772 4.54776 9.57747 4.41276 9.81972M4.41276 8.18022L11.5873 4.19472M4.41276 9.81972L11.5873 13.8052M11.5873 4.19472C11.6924 4.39282 11.8361 4.56797 12.0097 4.70991C12.1834 4.85186 12.3836 4.95776 12.5987 5.02143C12.8138 5.08509 13.0394 5.10523 13.2624 5.08069C13.4853 5.05614 13.7011 4.98739 13.8972 4.87846C14.0933 4.76953 14.2657 4.62261 14.4043 4.44628C14.5429 4.26995 14.645 4.06775 14.7046 3.85151C14.7641 3.63526 14.78 3.40931 14.7512 3.18686C14.7225 2.96442 14.6496 2.74994 14.537 2.55597C14.3151 2.17372 13.952 1.89382 13.5259 1.77643C13.0997 1.65904 12.6445 1.71352 12.2582 1.92818C11.8718 2.14284 11.585 2.50053 11.4596 2.92436C11.3341 3.34819 11.38 3.80433 11.5873 4.19472ZM11.5873 13.8052C11.4796 13.999 11.4112 14.2121 11.3859 14.4323C11.3606 14.6525 11.3789 14.8756 11.4398 15.0887C11.5007 15.3019 11.603 15.5009 11.7408 15.6746C11.8787 15.8482 12.0494 15.9929 12.2431 16.1006C12.4369 16.2082 12.65 16.2767 12.8702 16.302C13.0905 16.3273 13.3135 16.3089 13.5267 16.248C13.7398 16.1871 13.9389 16.0848 14.1125 15.947C14.2861 15.8092 14.4309 15.6385 14.5385 15.4447C14.7559 15.0534 14.809 14.5917 14.686 14.1612C14.563 13.7307 14.274 13.3668 13.8826 13.1493C13.4913 12.9319 13.0296 12.8789 12.5991 13.0019C12.1686 13.1249 11.8047 13.4139 11.5873 13.8052Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}