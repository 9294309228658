import React from "react";
import "../styles/footer.scss";

import ferrara from "../assets/icons/ferrara.svg";
import modena from "../assets/icons/modena.svg";
import forli from "../assets/icons/forli.svg";
import piacenza from "../assets/icons/piacenza.svg";
import company from "../assets/icons/openlab-company.svg";

import cookie from "../assets/pdfs/20240416 Informativa cookie - openlabdigital_com.docx.pdf";

const footer = {
  row1: [
    {
      title: "Contatti",
      content: [
        "Open Lab S.R.L.",
        "Viale Buon Pastore, 43, 41124 Modena MO, Italia",
        "P.IVA: 03960450363",
      ],
      logo: company,
    },
    {
      title: "Email",
      content: ["modena@labaperti.it", "ferrara@labaperti.it"],
    },
    {
      title: "Email",
      content: ["piacenza@labaperti.it", "forli@labaperti.it"],
    },
  ],
  row2: [
    "Copyright© 2023 Laboratori Aperti. All rights reserved.",
    "Privacy Policy",
    "Legal",
    "Terms of Use",
  ],
  row3: [
    <img src={ferrara} />,
    <img src={modena} />,
    <img src={forli} />,
    <img src={piacenza} />,
  ],
};
const Footer = () => {
  return (
    <div className="footer-container">
      <div className="infos">
        {footer.row1.map((elem, index) => (
          <>
            <div key={index} className={`col c${index + 1}`}>
              <p className="title"> {elem.title} </p>
              {elem.content.map((content, index) => (
                <p className={`content co${index + 1}`} key={index}>
                  {" "}
                  {content}{" "}
                </p>
              ))}
            </div>
          </>
        ))}
      </div>

      <div className="copyright">
        <div className="col1">
          {footer.row2.slice(0, 1).map((elem, index) => (
            <span key={index}> {elem} </span>
          ))}
        </div>

        <div className="vertical-line v1"> </div>

        <div className="col2">
          {footer.row2.slice(1).map((elem, index) => (
            <a href="/" key={index}>
              <span className={`el${index + 1}`}> {elem} </span>
            </a>
          ))}
        </div>
      </div>

      <div className="cookies-row">
        <img
          src={company}
          alt="open lab digital"
          className="company-logo"
        />
        <a href={cookie} target="_blank">
          <div className="download-cookie-button">
            <span> Cookies Policy </span>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.15625 4.3125L9.84375 9L5.15625 13.6875"
                stroke="black"
                strokeOpacity="0.7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </a>
      </div>

      <div className="logos">
        {footer.row3.map((logo, index) => (
          <div key={index} className={`logo l${index + 1}`}>
            {logo}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
