import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion"

import { marked } from 'marked'
import * as DOMPurify from 'dompurify'

import "../styles/askmeanything.scss"

import { fetchChat, askChat, getAllChats, clearChat } from '../utils/customGPT'
import useFetch from "../utils/useFetch"

export default function AskMeAnything() {

  const [sessionId, setSessionId] = useState(null)

  const [input, setInput] = useState(null)
  const [thinking, setThinking] = useState(null)

  const [messages, setMessages] = useState([])

  return (
    <div className="ask-page">
      <div style={{ height: '88px' }} />
      <div className="ask-container">
        <Recommended
          sessionId={sessionId} setSessionId={setSessionId.bind(this)}
          setThinking={setThinking.bind(this)} thinking={thinking}
          messages={messages} setMessages={setMessages.bind(this)}
        />
        <Chat
          sessionId={sessionId} setSessionId={setSessionId.bind(this)}
          setInput={setInput.bind(this)} input={input}
          setThinking={setThinking.bind(this)} thinking={thinking}
          messages={messages} setMessages={setMessages.bind(this)}
        />
      </div>
    </div>
  )

}

function Chat({ sessionId, setSessionId, setInput, input, thinking, setThinking, messages, setMessages }) {

  const inputRef = useRef()
  const buttonRef = useRef()

  const userDataResponse = useFetch('/french/users/me')
  const userPicture = userDataResponse?.data?.data?.attributes?.profile_photo_url

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        buttonRef.current.click();
      }
    };

    inputRef.current.addEventListener("keydown", handleEnterKey);

  }, []);

  return (
    <div className="container chat">
      <div className="messages-container">
        {
          messages && (
            <div className="messages">
              {messages.map((message, id) => (
                <AnimatePresence key={id}>
                  <div className="message">
                    {
                      message.user_query &&
                      <motion.div
                        key={id + "_question"}
                        className='sent-container'
                      // transition = {{duration: 0.5}}
                      >
                        <p className="sent">
                          {message.user_query.replace('&apos;', "'")}
                        </p>
                        <div className="chat-avatar">
                          {
                            userPicture ?
                              <img src={userPicture} />
                              :
                              <div className="chat-avatar-default">
                                {icons.user}
                              </div>
                          }
                        </div>
                      </motion.div>
                    }
                    {
                      message.openai_response &&
                      <motion.div
                        key={id + "_answer"}
                        className="received-container"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1 }}
                      >
                        <div className="chat-avatar">
                          {icons.logo}
                        </div>
                        <div className="received-mkd-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(message.openai_response)) }} />
                      </motion.div>
                    }
                  </div>
                </AnimatePresence>
              ))}
              <div style={{ width: "100%", height: "200px" }} />
            </div>
          )
        }
      </div>
      <div className="input-container">
        <div className="ask-shadow" />
        {
          messages && messages.length > 0 &&
          <button className="new-chat" onClick={() => { clearChat(setSessionId, setMessages, inputRef) }}>
            {icons.plus}
            <p>Nuova sessione</p>
          </button>
        }
        <div className="ask">
          <input type="text" id='chat-input' placeholder="Chiedi..." onChange={(e) => { setInput(e.target.value) }} ref={inputRef} />
          <button ref={buttonRef} disabled={thinking} className={"send send_" + thinking} onClick={() => { askChat(sessionId, setSessionId, input, inputRef, messages, setMessages, setThinking) }}>
            {icons[thinking ? "dots" : "arrow"]}
            {!thinking && <p>Enter</p>}
          </button>
        </div>
      </div>


    </div>
  )
}

function Previous({ sessionId, setMessages }) {

  const [chats, setChats] = useState(null)

  useEffect(() => {
    getAllChats(setChats)
  }, [])

  useEffect(() => {
    console.log('chats', chats)
  }, [chats])

  return (
    <div className="container previous">
      <p className="title">Domande precedenti</p>
      {
        chats && chats.length &&
        <div className="previous-chats">
          {chats.map((chat, id) => (
            <div key={id} className='previous-chat' onClick={() => { fetchChat(sessionId, setMessages) }}>
              {chat.name.replace('&apos;', "'")}
            </div>
          ))}
        </div>
      }
    </div>
  )
}

function Recommended({ sessionId, setSessionId, messages, setMessages, setThinking }) {

  return <div className="recommended container">
    <p className="title">Domande raccomandate</p>
    {
      recommendedQuestions && recommendedQuestions.length &&
      <div className="previous-chats">
        {recommendedQuestions.map((q, id) => (
          <div key={id} className='previous-chat' onClick={() => { askChat(sessionId, setSessionId, q, null, messages, setMessages, setThinking) }}>
            {q}
          </div>
        ))}
      </div>
    }
  </div>
}

const recommendedQuestions = [
  "⁠Che cosa sono i Laboratori Aperti?",
  "Che tipologia di attività si svolgono nei Laboratori Aperti?",
  "Come posso prenotare uno spazio all’interno dei Laboratori Aperti?",
  "⁠Che cosa significa Open Innovation?",
  "Di cosa si occupa Open Lab?",
  "⁠Quali sono i servizi offerti per i giovani?",
]

const icons = {
  user: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
    </svg>
  ),
  arrow: (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fff" viewBox="0 0 256 256">
      <path d="M204,32V176a12,12,0,0,1-12,12H77l27.52,27.51a12,12,0,0,1-17,17l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,1,1,17,17L77,164H180V32a12,12,0,0,1,24,0Z"></path>
    </svg>
  ),
  dots: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
    </svg>
  ),
  plus: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
  ),
  logo: (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.92276 5.62528L9.5 1.25056L17.0772 5.62528V14.3747L9.5 18.7494L1.92276 14.3747V5.62528Z"
        stroke="white"
        strokeWidth="1.3"
      />
      <path
        d="M9.50346 10.0002V19.3139M17.4201 14.5639L1.49365 5.43646M1.49365 14.5639L17.5133 5.43646M13.6946 7.67176L5.77797 3.01489"
        stroke="white"
        strokeWidth="1.3"
      />
      <path
        d="M13.6021 7.67163V16.7059"
        stroke="white"
        strokeWidth="1.3"
      />
    </svg>
  )
}