
export const cleanURI = (uri) => {
  if (!uri) return '';
  let coverPict = encodeURI(uri)
  let coverPictArray = coverPict.split("/")
  let coverPictArrayLength = coverPictArray.length
  let pictName = coverPictArray[coverPictArrayLength - 1]
  coverPictArray[coverPictArrayLength - 1] = pictName.replaceAll("(", "%28").replaceAll(")", "%29")
  return coverPictArray.join("/");
}

