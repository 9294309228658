import React, { useState, useEffect } from "react";
import "../styles/shareModal.scss";
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { preventBodyScrolling } from "../utils/preventBodyScrolling";

export default function ShareModal() {

  const shareModal = useStoreState((state) => state.shareModal);
  const setShareModal = useStoreActions((actions) => actions.setShareModal);

  const link = shareModal
  const messageText = `Exploring the digital frontier with Open Lab Digital! 🚀 Dive into innovation and creativity at ${link} #OpenLabDigital #TechInnovation #DigitalExploration`;

  const [copied, setCopied] = useState(false);
  useEffect(() => {
    // Reset copied state when modal is closed
    if (!shareModal) {
      setCopied(false);
    }
  }, [shareModal]);

  useEffect(() => {
    if (shareModal) {
      preventBodyScrolling("hidden");
    } else {
      preventBodyScrolling("unset");
    }
  }, [shareModal]);

  const copyLink = () => {

    navigator.clipboard
      .writeText(shareModal)
      .then(() => {
        console.log("Link copied to clipboard:", shareModal);
        setCopied(true);
        // You can add a notification or confirmation message here
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        // Handle errors or show a message to the user
      });
  };

  return (
    shareModal && (
      <>
        <div
          className="share-modal-shadow"
          onClick={() => {
            setShareModal(false);
          }}
        />
        <div className="share-modal">
          <div className="topbar">
            <button
              className="close"
              onClick={() => {
                setShareModal(false);
              }}
            >
              {icons.close}
            </button>
          </div>
          <div className="buttons">
            <a
              class="share-button twitter-share-button"
              href={`https://twitter.com/intent/tweet?text=${messageText}`}
            >
              {icons.twitter}
              <p>Condividi su Twitter</p>
            </a>

            <a
              onClick={() => copyLink()}
              class="share-button twitter-share-button"
            >
              {!copied ? icons.copy : icons.copied}
              <p>Copia il link</p>
            </a>
          </div>
        </div>
      </>
    )
  );
}

const icons = {
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  ),
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="#fff"
      viewBox="0 0 256 256"
    >
      <path d="M247.39,68.94A8,8,0,0,0,240,64H209.57A48.66,48.66,0,0,0,168.1,40a46.91,46.91,0,0,0-33.75,13.7A47.9,47.9,0,0,0,120,88v6.09C79.74,83.47,46.81,50.72,46.46,50.37a8,8,0,0,0-13.65,4.92c-4.31,47.79,9.57,79.77,22,98.18a110.93,110.93,0,0,0,21.88,24.2c-15.23,17.53-39.21,26.74-39.47,26.84a8,8,0,0,0-3.85,11.93c.75,1.12,3.75,5.05,11.08,8.72C53.51,229.7,65.48,232,80,232c70.67,0,129.72-54.42,135.75-124.44l29.91-29.9A8,8,0,0,0,247.39,68.94Zm-45,29.41a8,8,0,0,0-2.32,5.14C196,166.58,143.28,216,80,216c-10.56,0-18-1.4-23.22-3.08,11.51-6.25,27.56-17,37.88-32.48A8,8,0,0,0,92,169.08c-.47-.27-43.91-26.34-44-96,16,13,45.25,33.17,78.67,38.79A8,8,0,0,0,136,104V88a32,32,0,0,1,9.6-22.92A30.94,30.94,0,0,1,167.9,56c12.66.16,24.49,7.88,29.44,19.21A8,8,0,0,0,204.67,80h16Z"></path>
    </svg>
  ),
  copy: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
      />
    </svg>
  ),
  copied: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  ),
};
