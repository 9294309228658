//PACKS
import React, { useState, useEffect, memo } from 'react'
import { motion } from "framer-motion"

//STYLES
import "../styles/featuredCarousel.scss"

//COMPONENTS
import FeaturedCard from './FeaturedCard'

import { carouselAnimation, cardAnimations, handleClicks } from "../utils/handleFeaturedCarousel"

const FeaturedCarousel = (props) => {

  //Starts with 2 because the first 2 cards are from the past
  const [openPosition, setOpenPosition] = useState(0)
  const width = window.innerWidth

  const cards = props.content?.data?.data

  return (
    <div className="featured-carousel">
      <motion.div
        className='container'
        animate={{ x: carouselAnimation(openPosition) }}
      >
        {cards && cards?.slice(0, 10)?.map((card, index) => (
          <motion.div
            className='card'
            key={index}
            onClick={() => {
              handleClicks(
                {
                  ...card, index,
                  type: cardAnimations(index, openPosition, width).type
                },
                openPosition, setOpenPosition, width
              )
            }}
            animate={{ width: cardAnimations(index, openPosition, width).width }}
          >
            <FeaturedCard
              content={card}
              type={cardAnimations(index, openPosition, width).type}
            />
          </motion.div>

        ))}
      </motion.div>
    </div>
  )
}

export default memo(FeaturedCarousel);