//PACKS
import React, { useState, useEffect } from 'react';

//COMPONENTS
import BadInputAlert from '../components/BadInputAlert';

//UTILS
import register from '../utils/useRegister';

import file from "../assets/pdfs/20240416 Informativa area riservata - openlabdigital_com.docx.pdf";


export default function Register() {

  const [error, setError] = useState(null)

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
  }, [])

  return (
    <>
      <BadInputAlert error={error} />
      <div className="h-screen v-max h-full bg-slate-100">
        <div className="flex min-h-full flex-1 flex-col justify-center py-12  sm:px-6 lg:px-8">
          <RegisterHeader />
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <RegisterForm setError={setError} />
            <p className="mt-10 mb-5 text-center text-sm text-gray-500">
              Già hai un account?{' '}
              <a href="/login" className="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Accedi!
              </a>
            </p>
            <a href={file} target="_blank" style={{ textDecoration: 'none' }}>
              <div className="download-cookie-button" style={buttonStyles.downloadButton} >
                <span style={buttonStyles.buttonText}> Informativa Privacy </span>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={buttonStyles.svgIcon}
                >
                  <path
                    d="M5.15625 4.3125L9.84375 9L5.15625 13.6875"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}


// Define styles in a separate object
const buttonStyles = {
  downloadButton: {
    cursor: 'pointer',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 20px',
    borderRadius: '10px',
    background: '#000000',
    marginLeft: 'calc(50% - 187px / 2)',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  svgIcon: {
    marginLeft: '10px',
  },
};

function RegisterHeader() {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <svg
        width="32"
        height="34"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className='mx-auto invert opacity-4'
      >
        <path
          d="M1.92276 5.62528L9.5 1.25056L17.0772 5.62528V14.3747L9.5 18.7494L1.92276 14.3747V5.62528Z"
          stroke="white"
          strokeWidth="1.3"
        />
        <path
          d="M9.50346 10.0002V19.3139M17.4201 14.5639L1.49365 5.43646M1.49365 14.5639L17.5133 5.43646M13.6946 7.67176L5.77797 3.01489"
          stroke="white"
          strokeWidth="1.3"
        />
        <path
          d="M13.6021 7.67163V16.7059"
          stroke="white"
          strokeWidth="1.3"
        />
      </svg>

      <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Crea un nuovo account
      </h2>
    </div>
  )
}

function RegisterForm({ setError }) {
  return (
    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
      <form className="space-y-6" onSubmit={async (e) => { register(e, setError) }}>
        <div className="col-start-1 col-end-1">
          <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
            Nome
          </label>
          <div className="mt-2">
            <input
              id="first_name"
              name="first_name"
              type="text"
              autoComplete="given-name"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-start-2 col-end-2">
          <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
            Cognome
          </label>
          <div className="mt-2">
            <input
              id="last_name"
              name="last_name"
              type="text"
              autoComplete="family-name"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className='col-span-full'>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="username"
              type="email"
              autoComplete="email"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className='col-span-full'>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
            Password
          </label>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className='col-span-full'>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
            Conferma password
          </label>
          <div className="mt-2">
            <input
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              autoComplete="new-password"
              required
              className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className='col-span-full'>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  )
}

