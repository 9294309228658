//PACKS
import React from "react";
import { useParams } from "react-router-dom";

//UTILS
import useFetch from "../utils/useFetch";
import Responsive from '../utils/Responsive';
import { cleanURI } from "../utils/cleanURI";

//STYLES
import "../styles/cityPage.scss"

//COMPONENTS
import BlurredBackground from '../components/BlurredBackground'
import CityPageStackedCard from "../components/CityPageStackedCard";
import FeaturedCarousel from '../components/FeaturedCarousel';
import MobileCarousel from "../components/MobileCarousel"
import CarouselsCityGrid from "../components/CityCards/CarouselsCityGrid";
import StatisticsGrid from "../components/StatisticsGrid";
import Footer from "../components/Footer";

function redirectBadRequest(cityName) {
  if (cityName !== 'forli' && cityName !== 'ferrara' && cityName !== 'modena' && cityName !== 'piacenza') {
    window.location.href = '/';
  }
}

export default function CityPage() {

  const { cityName } = useParams();

  redirectBadRequest(cityName)

  const featuredActivities = useFetch('/french/activities/last?city=' + cityName)
  const cities = useFetch('/french/organizers')

  let backgroundImage = null
  let downloadBrochure = null
  if (cities?.data) {
    cities.data.data.forEach(element => {
      if (cityName === element.attributes.slug) {
        downloadBrochure = cleanURI(element.attributes.brochure_url)
        backgroundImage = cleanURI(element.attributes.cover_pict)
      }
    });
  }

  return (
    featuredActivities && cities && backgroundImage &&
    <div className="cityPage-container">
      <BlurredBackground image={backgroundImage} />
      <div style={{ height: '88px' }} />
      <div className="fit">
        <CityPageStackedCard cityName={cityName} stackedCardBg={backgroundImage} downloadBrochure={downloadBrochure} />
      </div>
      <div className="container-w-titlepad">
        <p className="titlepad titlepad-color">
          Eventi curati dai Laboratori Aperti
        </p>
        <Responsive>
          <div className="fit ">
            <MobileCarousel cards={featuredActivities?.data?.data} color={"dark"} />
          </div>
          <FeaturedCarousel content={featuredActivities} />
        </Responsive>
      </div>
      <CarouselsCityGrid calendarCards={featuredActivities?.data?.data.slice(0, 2)} cityName={cityName} cities={cities?.data?.data} />
      <div className='sectionSpacer' />
      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  );
};

















const dummyActivityCardsOne = {
  "title": "Suggeriti per te",
  "cards": [
    {
      "type": "video",
      "title": "This is a dummy title ",
      "sub": "Pubblicato 18 Oct 2023",
      "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "hours": "12 Oct 09:00 - 12:00"
    },
    {
      "type": "video",
      "title": "This is a dummy title that is just a bit too big",
      "sub": "Pubblicato 18 Oct 2023",
      "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "hours": "12 Oct 09:00 - 12:00"
    },
    {
      "type": "video",
      "title": "This is a dummy title that is just a bit too big",
      "sub": "Pubblicato 18 Oct 2023",
      "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "hours": "12 Oct 09:00 - 12:00"
    }
  ]
}

const dummyFeaturedCards = [

  {
    "type": "video",
    "title": "This is a dummy title ",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  },
  {
    "type": "video",
    "title": "This is a dummy title that is just a bit too big",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  },
  {
    "type": "video",
    "title": "This is a dummy title that is just a bit too big",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  },
  {
    "type": "video",
    "title": "This is a dummy title that is just a bit too big",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  },
  {
    "type": "video",
    "title": "This is a dummy title that is just a bit too big",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  },
  {
    "type": "video",
    "title": "This is a dummy title that is just a bit too big",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  },
  {
    "type": "video",
    "title": "This is a dummy title that is just a bit too big",
    "location": "Via Castelnuovo 10, Ferrara",
    "image": "https://images.unsplash.com/photo-1682687982468-4584ff11f88a?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "day": "22 Oct",
    "hours": "09:00 - 12:00"
  }

]


