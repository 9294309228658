import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/api";

import { motion } from "framer-motion";
import "../styles/citiesGridComponent.scss";
import FeaturedCard from "./FeaturedCard";


import {
  ferraraImages,
  forliImages,
  modenaImages,
  piacenzaImages,
} from "../utils/coworkingImages";
import HomepageCitiesVerticalSlider from "./HomepageCitiesVerticalSlider";
import { cleanURI } from "../utils/cleanURI";

const CitiesGridComponent = (props) => {

  //STATES
  const [city, setCity] = useState(null);
  const [cityImages, setCityImages] = useState([]);
  const [bgImage, setBgImage] = useState();
  const [cityIndex, setCityIndex] = useState();

  useEffect(() => {
    setCity(props.city);
    setCityImages(props.cities)
    setCityIndex(props.keyIndex)
  }, [props]);

  // Determines the index of a city in the cityImages data array based on the cityName provided.
  // If 'Forlì' is the cityName, the index is found using the 'forli' slug. Otherwise, it's matched by the cityName's slug.

  // Finds the index of the city image in cityImages data based on the provided cityName.

  // useEffect(() => {
  //   const cityImage = cityIndex !== -1 ? cityIndex : null;
  //   setBgImage(
  //     cityImage
  //       ? `url(${cityImages[cityImage]?.attributes?.cover_pict})`
  //       : "url(DEFAULT_FALLBACK_IMAGE_URL)"
  //   );
  // }, [city, cityImages, props.cityName]);

  const cityImage = cityIndex !== -1 ? cityIndex : null;

  console.log(cityImages[cityImage]);
  console.log(cityImages[cityImage]?.attributes?.cover_pict);
  const coverPict = cleanURI(cityImages[cityImage]?.attributes?.cover_pict)

  return (
    city && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.1, ease: "linear" }}
        className="cities-grid "
      >
        <div className="left-side">
          {/* title */}
          <div className="shadow" />
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="city-title-section blurredHomepage"
            style={{
              backgroundImage: `url(${coverPict})`,
            }}
          >
            <div className="up-row">
              <div className="content">
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="title"
                >
                  {city.name}
                </motion.p>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.56 }}
                  className="description"
                >
                  {city.location}
                </motion.p>
              </div>
            </div>

            <div className="down-row">
              <div className="event-details">
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.7 }}
                  className="title"
                >
                  Scopri tutto su
                </motion.p>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                >
                  Il Laboratorio Aperto di {city.name}
                </motion.p>
              </div>
              <a href={`/citta/${city.slug.toLowerCase()}`}>
                <div className="button">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13865 10.8613L10.8614 1.13859M10.8614 1.13859H2.11092M10.8614 1.13859V9.88904"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </motion.div>
        </div>
        <div className="right-side">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            style={{ position: "relative" }}
            className="ev-card blurredHomepage"
          >
            {/* <FeaturedCard content={city.activities?.data[0]} type="open" /> */}
            <FeaturedCard content={city?.activities?.data[0]} type="open" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.65 }}
            style={{ position: "relative" }}
            className="ev-card blurredHomepage"
          >
            {/* <FeaturedCard content={city?.activities} type="open" /> */}
            <HomepageCitiesVerticalSlider             
              content={cityImages}
              cityName={props.cityName}
              cityIndex={cityIndex}
            />
          </motion.div>
        </div>
      </motion.div>
    )
  );
};

export default CitiesGridComponent;

// function generateCityImages(city, count) {
//   const images = [];
//   for (let i = 1; i <= count; i++) {
//     const imageUrl = `../../assets/images/coworking_${city}_${i}.jpeg`;
//     // const link = `https://example.com/${city}/image${i}`;
//     const link = `https://laboratorioaperto${city}.it/gli-spazi/`
//     const title = `${city}`
//     images.push({ imageUrl, link, title });
//   }
//   return images;
// }

// const cityImages = {
//   data: [
//     {
//       id: "1",
//       type: "activity_organizer",
//       attributes: {
//         name: "Castelnuovo Placemaking Hub",
//         description: "",
//         slug: "castelnuovo",
//         cover_pict: null,
//         cover_video: null,
//         coworking_picts: [],
//         selection_videos: [],
//       },
//     },
//     {
//       id: "3",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Ferrara",
//         description: "",
//         slug: "ferrara",
//         cover_pict:
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBZdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6ec65b24c17c7e888231ec1d73c4a628f72b7bab/coworking_ferrara_2.jpeg",
//         cover_video: null,
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBZdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6ec65b24c17c7e888231ec1d73c4a628f72b7bab/coworking_ferrara_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBaZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--34b7fdded6c1d36e54041d0c19730cdc45442a04/coworking_ferrara_3.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBhUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--93b82d1a1234fcc68447b27dc0a1be1b0d71d51f/coworking_ferrara_4.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBKQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f8006e5888cdbc5a47c73c2a4e8b0bdf50f07729/Spazi%20di%20Coworking%20-%20Laboratorio%20Aperto%20di%20Ferrara.jpg",
//         ],
//         selection_videos: [],
//       },
//     },
//     {
//       id: "5",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Forlì",
//         description: "",
//         slug: "forli",
//         cover_pict:
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBaZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--34b7fdded6c1d36e54041d0c19730cdc45442a04/coworking_ferrara_3.jpeg",
//         cover_video:
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBQUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--682f3eb38e7237cef313451ed602000f449b33e4/LA_Inaugurazione.mp4",
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWVk9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1af5bc15ea50dece1fbd5a29ef50e1a362679d13/coworking_forli_4.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWHM9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1aecab0176f43a0050a78b9dd37e7ef012d61c09/coworking_forli_1.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWDQ9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--18cace17a6c791bb73c320499c0c758b4bb078d6/coworking_forli_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWUU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--60277c3a57dbf5c4cbce388a52569c5373b79df0/coworking_forli_3.jpeg",
//         ],
//         selection_videos: [],
//       },
//     },
//     {
//       id: "2",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Modena",
//         description: "",
//         slug: "modena",
//         cover_pict:
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBkZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c80305acd2a43601b57dc802c6191c6932d8de53/coworking_modena_2.jpeg",
//         cover_video:
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2f3aaddf7b581cac08c16b0358042a5c538a80e4/Python_Barozzi.mp4",
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBkZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c80305acd2a43601b57dc802c6191c6932d8de53/coworking_modena_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBLQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8805bac5111b99d6bed6be5175b26484ebc0f7b0/Spazi%20di%20Coworking%20-%20Laboratorio%20Aperto%20di%20Modena.jpg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBlUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--894666d3369c6ce0b7d1f44a88d3a094f868bde6/coworking_modena_3.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBmUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--263ca6896ac5e9ce47bc618b72a9af34fb205cc1/coworking_modena_4.jpeg",
//         ],
//         selection_videos: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2f3aaddf7b581cac08c16b0358042a5c538a80e4/Python_Barozzi.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b44c1b2f4baa2ae58dcb1f99f015b3eef1106558/Clap.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBPQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b03120332c4c9a3587279c2266897f030e1bb153/Festival%20della%20sostenibilit%C3%A0.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBPZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--22af895925d191e7f00d591f84cbf594d959de88/Illusion.mp4",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBPdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--646169244156d22bc591ce32e163e43587667b86/Smart_life.mp4",
//         ],
//       },
//     },
//     {
//       id: "4",
//       type: "activity_organizer",
//       attributes: {
//         name: "Laboratorio Aperto di Piacenza",
//         description: "",
//         slug: "piacenza",
//         cover_pict:
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWUU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--60277c3a57dbf5c4cbce388a52569c5373b79df0/coworking_forli_3.jpeg",
//         cover_video: null,
//         coworking_picts: [
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6abd4ebcabac61ecf43b9eb23c61d2a9a982ffa9/Spazi%20di%20Coworking%20-%20Laboratorio%20Aperto%20di%20Piacenza.JPG",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBiQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c68eedfd3b5f7f78929f682903fb9ea06ff33cd9/coworking_piacenza_2.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBjZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1b3cdfddf77e72ebb15d87e021fd615d7630944f/coworking_piacenza_4.jpeg",
//           "https://bo.openlabdigital.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBidz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--191670563c0e38fd62a8fcba45a2262b62d360cd/coworking_piacenza_3.jpeg",
//         ],
//         selection_videos: [],
//       },
//     },
//   ],
//   meta: {
//     total: 5,
//   },
// };

const content = [
  {
    index: 0,
    city: "forlì",
    images: forliImages,
  },
  {
    index: 1,
    city: "modena",
    images: modenaImages,
  },
  {
    index: 2,
    city: "ferrara",
    images: ferraraImages,
  },
  {
    index: 3,
    city: "piacenza",
    images: piacenzaImages,
  },
];
